export const LINK_SETTINGS = 'hsLink';
export const hslinkConfig = {
  HIDE_TARGET_BLANK_AND_NO_FOLLOW: 'hideTargetBlankAndNoFollow',
  ALLOW_RELATIVE_LINKS: 'allowRelativeLinks',
  ALLOW_FILE_DOWNLOAD: 'allowFileDownload',
  ALLOW_MEETING_LINKS: 'allowMeetingLink',
  ALLOW_ANCHOR_LINKS: 'allowAnchorLinks',
  ALLOW_RAW_LINKS_ONLY: 'allowRawLinksOnly',
  EMAIL_SUBSCRIPTION_LINK_TYPES: 'emailSubscriptionLinkTypes',
  ALLOW_EMAIL_WEB_VERSION_LINK: 'allowEmailWebVersionLink'
};
export const savedLinkFields = {
  HREF: 'href',
  TEXT: 'linkText',
  REL: 'rel',
  TARGET: 'target'
};
export const LINK_SHORTCUT = 'Meta+K';
export const LINK_TARGET_BLANK_KEY = '_blank';