export const BLOGS_CREATE_BLOG_SUCCEEDED = 'BLOGS_CREATE_BLOG_SUCCEEDED';
export const BLOGS_CREATE_BLOG_FAILED = 'BLOGS_CREATE_BLOG_FAILED';
export const BLOGS_FETCH_BLOG = 'BLOGS_FETCH_BLOG';
export const BLOGS_FETCH_BLOGS = 'BLOGS_FETCH_BLOGS';
export const BLOGS_FETCH_DEFAULT_BLOG = 'BLOGS_FETCH_DEFAULT_BLOG';
export const BLOGS_SAVE_BLOG_CACHE = 'BLOGS_SAVE_BLOG_CACHE';
export const BLOGS_CLEAR_CACHE = 'BLOGS_CLEAR_CACHE';
export const FORMS_FETCH_FORM = 'FORMS_FETCH_FORM';
export const FORMS_FETCH_FORM_DRAFT = 'FORMS_FETCH_FORM_DRAFT';
export const FORMS_FETCH = 'FORMS_FETCH';
export const FORMS_PROPERTIES_FETCH = 'FORMS_PROPERTIES_FETCH';
export const FORMS_SUBSCRIPTIONS_FETCH = 'FORMS_SUBSCRIPTIONS_FETCH';
export const FORMS_DEFAULT_CONSENT_FETCH = 'FORMS_DEFAULT_CONSENT_FETCH';
export const FORMS_CREATE = 'FORMS_CREATE';
export const FORMS_CLEAR_CACHE = 'FORMS_CLEAR_CACHE';
export const FORMS_UPDATE_FORM_DRAFT = 'FORMS_UPDATE_FORM_DRAFT';
export const FORMS_FETCH_PARENT_PAGES_REQUEST = 'FORMS_FETCH_PARENT_PAGES_REQUEST';
export const FORMS_FETCH_PARENT_PAGES_SUCCEEDED = 'FORMS_FETCH_PARENT_PAGES_SUCCEEDED';
export const FORMS_DISMISSED_PAGES_WARNING = 'FORMS_DISMISSED_PAGES_WARNING';
export const FORMS_FETCH_PARENT_PAGES_FAIL = 'FORMS_FETCH_PARENT_PAGES_FAIL';
export const FORMS_SAVE_FORM_DRAFT_REQUEST = 'FORMS_SAVE_FORM_DRAFT_REQUEST';
export const FORMS_SAVE_FORM_DRAFT_FAIL = 'FORMS_SAVE_FORM_DRAFT_FAIL';
export const FORMS_SAVE_FORM_DRAFT = 'FORMS_SAVE_FORM_DRAFT';
export const FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL = 'FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL';
export const FORMS_SAVE_FORM_CACHE = 'FORMS_SAVE_FORM_CACHE';
export const FORMS_FETCH_WORKFLOW_DEPENDENCIES = 'FORMS_FETCH_WORKFLOW_DEPENDENCIES';
export const FORMS_ADD_WORKFLOW_DEPENDENCY = 'FORMS_ADD_WORKFLOW_DEPENDENCY';
export const FORMS_DELETE_WORKFLOW_DEPENDENCY = 'FORMS_DELETE_WORKFLOW_DEPENDENCY';
export const WORKFLOWS_FETCH_WORKFLOW = 'WORKFLOWS_FETCH_WORKFLOW';
export const WORKFLOWS_FETCH_WORKFLOWS = 'WORKFLOWS_FETCH_WORKFLOWS';
export const WORKFLOWS_CREATE_WORKFLOW = 'WORKFLOWS_CREATE_WORKFLOW';
export const WORKFLOWS_FETCH_SUMMARIES = 'WORKFLOWS_FETCH_SUMMARIES';
export const WORKFLOWS_CLEAR_SUMMARIES = 'WORKFLOWS_CLEAR_SUMMARIES';
export const TAGS_FETCH_TAG = 'TAGS_FETCH_TAG';
export const TAGS_FETCH_TAG_BY_SLUG = 'TAGS_FETCH_TAG_BY_SLUG';
export const TAGS_FETCH_TAG_BY_NAME = 'TAGS_FETCH_TAG_BY_NAME';
export const TAGS_FETCH_TAGS = 'TAGS_FETCH_TAGS';
export const TAGS_SAVE_TAG_CACHE = 'TAGS_SAVE_TAG_CACHE';
export const TAGS_CLEAR_CACHE = 'TAGS_CLEAR_CACHE';
export const USERS_FETCH_USERS = 'USERS_FETCH_USERS';
export const USERS_FETCH_USERS_FOR_TEAMS = 'USERS_FETCH_USERS_FOR_TEAMS';
export const USERS_UPDATE_USERS_FOR_TEAMS = 'USERS_UPDATE_USERS_FOR_TEAMS';
export const HUBDB_FETCH_TABLE = 'HUBDB_FETCH_TABLE';
export const HUBDB_FETCH_TABLE_BY_NAME = 'HUBDB_FETCH_TABLE_BY_NAME';
export const HUBDB_FETCH_TABLES = 'HUBDB_FETCH_TABLES';
export const HUBDB_SAVE_TABLE_CACHE = 'HUBDB_SAVE_TABLE_CACHE';
export const HUBDB_CLEAR_CACHE = 'HUBDB_CLEAR_CACHE';
export const HUBDB_FETCH_ROW = 'HUBDB_FETCH_ROW';
export const HUBDB_FETCH_ROWS = 'HUBDB_FETCH_ROWS';
export const HUBDB_SAVE_ROW_CACHE = 'HUBDB_SAVE_ROW_CACHE';
export const HUBDB_SEARCH_ROWS = 'HUBDB_SEARCH_ROWS';
export const CRM_OBJECTS_FETCH_OBJECT = 'CRM_OBJECTS_FETCH_OBJECT';
export const CRM_OBJECTS_FETCH_OBJECTS = 'CRM_OBJECTS_FETCH_OBJECTS';
export const CRM_OBJECTS_SAVE_OBJECT_CACHE = 'CRM_OBJECTS_SAVE_OBJECT_CACHE';
export const CRM_OBJECTS_CLEAR_CACHE = 'CRM_OBJECTS_CLEAR_CACHE';
export const CRM_OBJECTS_FETCH_OBJECT_TYPES = 'CRM_OBJECTS_FETCH_OBJECT_TYPES';
export const CRM_OBJECTS_FETCH_OBJECT_SCHEMA = 'CRM_OBJECTS_FETCH_OBJECT_SCHEMA';
export const CRM_OBJECTS_SAVE_PROPERTY_CACHE = 'CRM_OBJECTS_SAVE_PROPERTY_CACHE';
export const PAGES_FETCH_PAGE = 'PAGES_FETCH_PAGE';
export const PAGES_FETCH_PAGES = 'PAGES_FETCH_PAGES';
export const PAGES_SAVE_PAGE_CACHE = 'PAGES_SAVE_PAGE_CACHE';
export const PAGES_CLEAR_CACHE = 'PAGES_CLEAR_CACHE';
export const PAYMENTS_FETCH_PAYMENT = 'PAYMENTS_FETCH_PAYMENT';
export const PAYMENTS_FETCH_PAYMENTS = 'PAYMENTS_FETCH_PAYMENTS';
export const PAYMENTS_FETCH_PAYMENT_ELIGIBILITY = 'PAYMENTS_FETCH_PAYMENT_ELIGIBILITY';
export const EMAILS_FETCH_EMAIL = 'EMAILS_FETCH_EMAIL';
export const EMAILS_FETCH_EMAIL_BUFFER = 'EMAILS_FETCH_EMAIL_BUFFER';
export const EMAILS_FETCH_EMAILS = 'EMAILS_FETCH_EMAILS';
export const EMAILS_FETCH_AUTOMATED_EMAILS = 'EMAILS_FETCH_AUTOMATED_EMAILS';
export const EMAILS_SAVE_EMAIL_CACHE = 'EMAILS_SAVE_EMAIL_CACHE';
export const EMAILS_UPDATE_EMAIL = 'EMAILS_UPDATE_EMAIL';
export const EMAILS_CLEAR_CACHE = 'EMAILS_CLEAR_CACHE';
export const EMAILS_CREATE_EMAIL = 'EMAILS_CREATE_EMAIL';
export const MENUS_CREATE_MENU = 'MENUS_CREATE_MENU';
export const MENUS_FETCH_MENU = 'MENUS_FETCH_MENU';
export const MENUS_FETCH_MENUS = 'MENUS_FETCH_MENUS';
export const MENUS_SAVE_MENU_CACHE = 'MENUS_SAVE_MENU_CACHE';
export const MENUS_CLEAR_CACHE = 'MENUS_CLEAR_CACHE';
export const CTAS_FETCH_CTA = 'CTAS_FETCH_CTA'; // new call to action

export const WEB_INTERACTIVES_FETCH_CALL_TO_ACTION = 'WEB_INTERACTIVES_FETCH_CALL_TO_ACTION';
export const WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION = 'WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION';
export const WEB_INTERACTIVES_SAVE_CALL_TO_ACTION_CACHE = 'WEB_INTERACTIVES_SAVE_CALL_TO_ACTION_CACHE';
export const WEB_INTERACTIVES_CLEAR_CALL_TO_ACTION_CACHE = 'WEB_INTERACTIVES_CLEAR_CALL_TO_ACTION_CACHE';
export const MEETINGS_CLEAR_MEETINGS = 'MEETINGS_CLEAR_MEETINGS';
export const MEETINGS_FETCH_MEETING = 'MEETINGS_FETCH_MEETING';
export const MEETINGS_FETCH_MEETINGS = 'MEETINGS_FETCH_MEETINGS';
export const MEETINGS_SAVE_MEETING_CACHE = 'MEETINGS_SAVE_MEETING_CACHE';
export const OPTIN_FETCH = 'OPTIN_FETCH';
export const OPTIN_UPDATE = 'OPTIN_UPDATE';
export const SALESFORCE_FETCH_CAMPAIGN = 'SALESFORCE_FETCH_CAMPAIGN';
export const SALESFORCE_FETCH_CAMPAIGNS = 'SALESFORCE_FETCH_CAMPAIGNS';
export const SALESFORCE_FETCH_INTEGRATION_STATUS = 'SALESFORCE_FETCH_INTEGRATION_STATUS';
export const SURVEYS_FETCH_SURVEY = 'SURVEYS_FETCH_SURVEY';
export const SURVEYS_FETCH = 'SURVEYS_FETCH';
export const SURVEYS_CLEAR_CACHE = 'SURVEYS_CLEAR_CACHE';
export const SURVEYS_SAVE_SURVEY_CACHE = 'SURVEYS_SAVE_SURVEY_CACHE';
export const SURVEYS_UPDATE_SURVEY_STATE = 'SURVEYS_UPDATE_SURVEY_STATE';
export const GOTOWEBINAR_FETCH_FUTURE_WEBINARS = 'GOTOWEBINAR_FETCH_FUTURE_WEBINARS';
export const GOTOWEBINAR_FETCH_SETTINGS = 'GOTOWEBINAR_FETCH_SETTINGS';
export const GOTOWEBINAR_FETCH_ELIGIBLE_FORMS = 'GOTOWEBINAR_FETCH_ELIGIBLE_FORMS';
export const CLEAR_ICONS = 'CLEAR_ICONS';
export const FETCH_ICON = 'FETCH_ICON';
export const FETCH_ICONS = 'FETCH_ICONS';
export const SAVE_ICON = 'SAVE_ICON';
export const FETCH_SELECTED_ICONS = 'FETCH_SELECTED_ICONS';
export const SITE_SETTINGS_FETCH = 'SITE_SETTINGS_FETCH';
export const THEME_SETTINGS_FETCH = 'THEME_SETTINGS_FETCH';
export const TARGETED_FETCH_CRITERION = 'TARGETED_FETCH_CRITERION';
export const TEAMS_FETCH_TEAMS = 'TEAMS_FETCH_TEAMS';
export const TEAMS_UPDATE_TEAMS_DATA = 'TEAMS_UPDATE_TEAMS_DATA';
export const VIDEOS_FETCH_VIDEO_2 = 'VIDEOS_FETCH_VIDEO_2';
export const VIDEOS_SAVE_VIDEO = 'VIDEOS_SAVE_VIDEO';
export const EMBED_FETCH_ASSET = 'EMBED_FETCH_ASSET';
export const EMBED_FETCH_MEDIA_BRIDGE_ASSET = 'EMBED_FETCH_MEDIA_BRIDGE_ASSET';
export const EMBED_UPDATE_ASSET = 'EMBED_UPDATE_ASSET';
export const EMBED_FETCH_MEDIA_BRIDGE_PROVIDERS = 'EMBED_FETCH_MEDIA_BRIDGE_PROVIDERS';
export const BRAND_SETTINGS_FETCH_COLORS = 'BRAND_SETTINGS_FETCH_COLORS';
export const GOOGLE_FONTS_FETCH_FONT = 'GOOGLE_FONTS_FETCH_FONT';
export const GOOGLE_FONTS_FETCH_FONTS = 'GOOGLE_FONTS_FETCH_FONTS';
export const GOOGLE_FONTS_CLEAR = 'GOOGLE_FONTS_CLEAR';
export const SELECT_GOOGLE_FONT = 'SELECT_GOOGLE_FONT';
export const GOOGLE_FONTS_FETCH_COMMON_FONTS = 'GOOGLE_FONTS_FETCH_COMMON_FONTS';
export const CUSTOM_FONTS_FETCH_FONT = 'CUSTOM_FONTS_FETCH_FONT';
export const CUSTOM_FONTS_BY_ASSET_FETCH_FONTS = 'CUSTOM_FONTS_BY_ASSET_FETCH_FONTS';
export const CUSTOM_FONTS_FETCH_FONTS = 'CUSTOM_FONTS_FETCH_FONTS';
export const CUSTOM_FONTS_CREATE_FONT = 'CUSTOM_FONTS_CREATE_FONT';
export const CUSTOM_FONTS_FILE_UPLOAD = 'CUSTOM_FONTS_FILE_UPLOAD';
export const CUSTOM_FONTS_STYLE_ASSIGNMENT = 'CUSTOM_FONTS_STYLE_ASSIGNMENT';
export const SELECT_CUSTOM_FONT = 'SELECT_CUSTOM_FONT';
export const ADD_FIELD_VALIDATION_ERROR = 'ADD_FIELD_VALIDATION_ERROR';
export const REMOVE_FIELD_VALIDATION_ERROR = 'REMOVE_FIELD_VALIDATION_ERROR';
export const SHIFT_FIELD_VALIDATION_ERROR = 'SHIFT_FIELD_VALIDATION_ERROR';
export const CAN_SPAM_SETTINGS_FETCH = 'CAN_SPAM_SETTINGS_FETCH';
export const CAN_SPAM_SETTINGS_BY_ID_FETCH = 'CAN_SPAM_SETTINGS_BY_ID_FETCH';
export const UPDATE_CAN_SPAM_SETTINGS_PENDING = 'UPDATE_CAN_SPAM_SETTINGS_PENDING';
export const UPDATE_CAN_SPAM_SETTINGS_SUCCESS = 'UPDATE_CAN_SPAM_SETTINGS_SUCCESS';
export const UPDATE_CAN_SPAM_SETTINGS_FAIL = 'UPDATE_CAN_SPAM_SETTINGS_FAIL';
export const PANTONE_FETCH_COLORS = 'PANTONE_FETCH_COLORS';
export const MODULE_FETCH_GRAPHQL_VARIABLES = 'MODULE_FETCH_GRAPHQL_VARIABLES';
export const BRAND_SETTINGS_FETCH_STARTED = 'BRAND_SETTINGS_FETCH_STARTED';
export const BRAND_SETTINGS_FETCH_SUCCEEDED = 'BRAND_SETTINGS_FETCH_SUCCEEDED';
export const BRAND_SETTINGS_FETCH_FAILED = 'BRAND_SETTINGS_FETCH_FAILED';
export const WHATSAPP_PHONE_NUMBERS_FETCH = 'WHATSAPP_PHONE_NUMBERS_FETCH';
export const BRAND_SETTINGS_BUSINESS_UNIT_FETCH = 'BRAND_SETTINGS_BUSINESS_UNIT_FETCH';
export const BRAND_SETTINGS_BRAND_KIT_FETCH = 'BRAND_SETTINGS_BRAND_KIT_FETCH';
export const BRAND_SETTINGS_LOGO_FETCH = 'BRAND_SETTINGS_LOGO_FETCH';