import { useCallback, useEffect, useMemo, useState } from 'react';
import useHandleIFrameError from './useHandleIFrameError'; // TODO: https://git.hubteam.com/HubSpot/MarketingOrchestration/issues/3354

export default function useSidebarIframeHandlers({
  withoutSidebar,
  isOpen,
  sendMessageRef
}) {
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [preload, setPreload] = useState(isOpen || withoutSidebar || false);
  const [iframeShouldRender, setIframeShouldRender] = useState(preload || false);
  const {
    IFrameError,
    handleSidebarInitError,
    handleSidebarReadyError
  } = useHandleIFrameError();
  const handleReady = useCallback(({
    sendMessage
  }) => {
    sendMessageRef.current = sendMessage;
    setIsIframeReady(true);
  }, [sendMessageRef]);
  const preLoadIframe = useCallback(() => {
    setPreload(true);
  }, []);
  useEffect(() => {
    if (isOpen && !preload) {
      setPreload(true);
    }
  }, [isOpen, preload]);
  useEffect(() => {
    // If either show or preload is true, we load the iframe.
    // show additionally means the iframe is visible
    if (!iframeShouldRender && (isOpen || preload)) {
      setIframeShouldRender(true);
    }
  }, [isOpen, preload, iframeShouldRender]);
  const iframeProps = useMemo(() => ({
    isReady: isIframeReady,
    preLoadIframe,
    handleReady,
    handleSidebarInitError,
    handleSidebarReadyError,
    preload,
    iframeShouldRender,
    IFrameError
  }), [isIframeReady, handleReady, handleSidebarInitError, handleSidebarReadyError, preload, iframeShouldRender, preLoadIframe, IFrameError]);
  return iframeProps;
}