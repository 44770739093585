import { STARTER } from 'self-service-api/constants/ProductLevels';
import { suiteStarter } from 'self-service-api/core/utilities/links';
import { getIsSuiteStarterEligible } from '../../ums/getPrioritizedUpgradeProduct';
import { getHubAndTierFromApiName } from '../../ums/utils'; // TODO add additional cases for pro / ent when ppp is ready

export const shouldRedirectToPersonalizedPP = (apiName, ownedProducts) => {
  if (!apiName || !ownedProducts) {
    return false;
  }

  const {
    tier
  } = getHubAndTierFromApiName(apiName);
  const isStarterTier = tier === STARTER.toLowerCase();
  const isFreePortal = ownedProducts.length === 0;
  const isSuiteStarterEligible = getIsSuiteStarterEligible(ownedProducts, isFreePortal);
  return isStarterTier && isSuiteStarterEligible;
}; // PPP = Personalized Pricing Page
// TODO add additional cases for pro / ent when ppp is ready

export const redirectUserToPPP = upgradeKey => {
  const redirectUrl = suiteStarter(upgradeKey);
  window.parent.open(redirectUrl);
};