export const Sections = {
  SEO: 'SEO'
};
export const CompletionStatuses = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  BLOCKED: 'BLOCKED',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
};
export const ContentTypes = {
  BLOG_POST: 'BLOG_POST',
  LANDING_PAGE: 'LANDING_PAGE',
  SITE_PAGE: 'SITE_PAGE',
  EXTERNAL_URL: 'EXTERNAL_PAGE'
};
export const PublishedStates = {
  UNVERIFIED: 'UNVERIFIED',
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  RESPONSE_CONTAINED_UNSUCCESSFUL_STATUS: 'RESPONSE_CONTAINED_UNSUCCESSFUL_STATUS',
  ERROR_OCCURRED_WHILE_MAKING_REQUEST: 'ERROR_OCCURRED_WHILE_MAKING_REQUEST',
  SSL_ERROR: 'SSL_ERROR',
  BLOCKED_BY_403: 'BLOCKED_BY_403'
};