import { MARS_LAYER } from 'HubStyleTokens/sizes';
export const DELETE_COMMENT_POPOVER_ID = 'delete-comment-popover';
export const MINI_COMMENTER_ID = 'mini-commenter-popover';
export const COLLAPSED_SIDEBAR_WIDTH = 43;
export const FLOATING_COLLAPSED_SIDEBAR_WIDTH = 56;
export const FLOATING_COLLAPSED_SIDEBAR_WIDTH_WITH_PADDING = FLOATING_COLLAPSED_SIDEBAR_WIDTH + 10;
export const MIN_SIDEBAR_WIDTH = 350;
export const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_WIDTH_CUTOFF = 1440;
export const SIDEBAR_ANIMATION_DURATION_SECONDS = 0.5;
export const SIDEBAR_ANIMATION_DURATION_MILLISECONDS = SIDEBAR_ANIMATION_DURATION_SECONDS * 1000;
export const DROP_AREA_WIDTH = FLOATING_COLLAPSED_SIDEBAR_WIDTH + 20;
export const DROP_AREA_MINUS_FLOATING_SIDEBAR = DROP_AREA_WIDTH - FLOATING_COLLAPSED_SIDEBAR_WIDTH;
export const FLOATING_SIDEBAR_Z_INDEX = Number.parseInt(MARS_LAYER, 10); // rgba(124, 152, 182, 0.3) is same as the EERIE color token with a 30% opacity

export const EERIE_OPACITY_30_PERCENT = 'rgba(124, 152, 182, 0.3)'; // rgba(66, 91, 118, 0.6) is same as the HEFFALUMP color token with a 60% opacity

export const HEFFALUMP_OPACITY_30_PERCENT = 'rgba(66, 91, 118, 0.3)';
export const COMMENT_LIST_PADDING_PX = 16;
export const THREAD_INDENT_PX = 14;