'use es6';

import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getIsCustomEmail = basicSelector(state => state.extraInitialState && state.extraInitialState.isCustomEmail);
export const getBaseApiUrl = basicSelector(state => state.extraInitialState.baseApiUrl);
export const getAppKey = basicSelector(state => {
  if (state.extraInitialState && state.extraInitialState.appKey) {
    return state.extraInitialState.appKey;
  }

  return '';
});