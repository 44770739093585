import { LIVE_COMMENTS, UNREAD_COMMENTS } from '../constants/gates';
import { isUngated } from '../utils/auth';
import { getAssetKeyFromAsset } from '../utils/multipleAssets';
import usePrevious from './usePrevious';
/**
 * NOTE - this will fire even if changes are from the current user.
 * We refetch as otherwise we can't guarantee we haven't missed
 * changes from other users
 */

export default function useIsCommentsDataStale({
  objectType,
  objectId,
  assetsLastUpdated
}) {
  const prevAssetsLastUpdated = usePrevious(assetsLastUpdated);

  if (!objectId || !isUngated(UNREAD_COMMENTS) || !isUngated(LIVE_COMMENTS)) {
    return false;
  }

  const assetKey = getAssetKeyFromAsset({
    objectId,
    objectType
  });
  return assetKey in prevAssetsLastUpdated && assetsLastUpdated[assetKey] !== prevAssetsLastUpdated[assetKey];
}