import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { useCallback, useMemo, useContext } from 'react';
import { setUserAttribute } from '../api/UserSettings';
import { UserSettingsContext } from '../contexts/UserSettingsProvider';
export default function useUserSettings() {
  const _useContext = useContext(UserSettingsContext),
        {
    setState
  } = _useContext,
        state = _objectWithoutPropertiesLoose(_useContext, ["setState"]);

  const setUserSetting = useCallback((key, value) => {
    setUserAttribute(key, value).then(payload => {
      setState(prevState => {
        const prevSettings = prevState && prevState.settings ? prevState.settings.filter(s => s.key !== key) : [];
        return {
          loading: false,
          error: null,
          settings: [...prevSettings, payload]
        };
      });
    }).catch(error => {
      setState(Object.assign({}, state, {
        loading: false,
        error
      }));
    });
  }, [setState, state]);
  const getUserSetting = useCallback(key => {
    let setting;

    if (state.settings && state.settings.length > 0) {
      setting = state.settings.find(s => s.key === key);
    }

    return setting ? setting.value : undefined;
  }, [state.settings]);
  return useMemo(() => {
    return Object.assign({}, state, {
      hasLoaded: !state.loading && !state.error,
      setUserSetting,
      getUserSetting
    });
  }, [getUserSetting, setUserSetting, state]);
}