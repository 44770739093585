export const SaveStates = {
  NO_UNSAVED_CHANGES: 'NO_UNSAVED_CHANGES',
  UNSAVED_CHANGES: 'UNSAVED_CHANGES',
  SAVING_CHANGES: 'SAVING_CHANGES',
  AUTO_SAVED_CHANGES: 'AUTO_SAVED_CHANGES',
  SAVED_CHANGES: 'SAVED_CHANGES',
  SAVE_FAILED: 'SAVE_FAILED'
};
export const FLOATY = 'floaty';
export const PUSHY = 'pushy';
export const COLLAPSED = 'collapsed';
export const SEPARATOR = 'separator';
export const PANEL_ANIMATION_TYPES = {
  PUSHY_TO_PUSHY: 'PUSHY_TO_PUSHY',
  FLOATY_TO_FLOATY: 'FLOATY_TO_FLOATY',
  PUSHY_TO_FLOATY: 'PUSHY_TO_FLOATY',
  FLOATY_TO_PUSHY: 'FLOATY_TO_PUSHY',
  FLOATY_TO_NONE: 'FLOATY_TO_NONE',
  PUSHY_TO_NONE: 'PUSHY_TO_NONE',
  NONE_TO_PUSHY: 'NONE_TO_PUSHY',
  NONE_TO_FLOATY: 'NONE_TO_FLOATY',
  NONE_TO_NONE: 'NONE_TO_NONE'
};
export const PANEL_ANIMATION_MAPPING = {
  [PUSHY]: {
    [FLOATY]: PANEL_ANIMATION_TYPES.PUSHY_TO_FLOATY,
    [PUSHY]: PANEL_ANIMATION_TYPES.PUSHY_TO_PUSHY,
    [COLLAPSED]: PANEL_ANIMATION_TYPES.PUSHY_TO_NONE
  },
  [FLOATY]: {
    [FLOATY]: PANEL_ANIMATION_TYPES.FLOATY_TO_FLOATY,
    [PUSHY]: PANEL_ANIMATION_TYPES.FLOATY_TO_PUSHY,
    [COLLAPSED]: PANEL_ANIMATION_TYPES.FLOATY_TO_NONE
  },
  [COLLAPSED]: {
    [FLOATY]: PANEL_ANIMATION_TYPES.NONE_TO_FLOATY,
    [PUSHY]: PANEL_ANIMATION_TYPES.NONE_TO_PUSHY,
    // Shouldn't be reached, but TS compiler doesn't realize that
    [COLLAPSED]: PANEL_ANIMATION_TYPES.NONE_TO_NONE
  }
};
export const TOGGLED_ANIMATION_DURATION = 200;