import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import userInfo from 'hub-http/userInfo';
import { getFullUrl } from 'hubspot-url-utils';
import { createClient } from 'usage-tracker-core';
import { once } from 'usage-tracker-core/common/helpers';
import { genericClient } from 'usage-tracker-core/client';
const origin = getFullUrl('app-api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';

const reportNetworkError = err => {
  return genericClient.reportError(err, {
    fingerprint: ['usage-tracker-js', 'network']
  });
}; // This prevents the Hub User Info from being requested multiple times
// instead it is requested only once, which should reduce unnecessary calls


const onceHubUserInfo = once(() => userInfo({
  ignoreRedirect: true
}));
const trackingClient = createClient({
  clientName: 'app',
  getDebug: genericClient.getDebug,
  getHstc: genericClient.getHstc,
  getTempStorage: genericClient.getTempStorage,
  setTempStorage: genericClient.setTempStorage,
  logWarning: genericClient.logWarning,
  logError: genericClient.logError,
  reportWarning: genericClient.reportWarning,
  reportError: genericClient.reportError,
  getEmail: () => onceHubUserInfo().then(({
    user
  }) => user.email),
  getHubId: () => onceHubUserInfo().then(({
    portal
  }) => portal.portal_id),
  getLang: () => Promise.resolve(I18n.Info).then(getLang).catch(genericClient.getLang),
  send: ({
    events,
    query,
    isBeforeUnload
  }) => {
    const endpoint = `${origin}${path}?${query}`;

    const sendXhr = () => {
      noAuthApiClient.post(endpoint, {
        data: events
      }).catch(reportNetworkError);
    };

    if (isBeforeUnload) {
      genericClient.sendBeacon(endpoint, events, sendXhr);
    } else {
      sendXhr();
    }
  }
});
export default trackingClient;