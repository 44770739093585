import produce from 'immer';
import { GET_CONTENT_ASSISTANT_SETTINGS, UPDATE_CONTENT_ASSISTANT_ENABLED } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  contentAssistanceEnabled: false
};
export const contentAssistantReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_CONTENT_ASSISTANT_SETTINGS:
      draft.contentAssistanceEnabled = action.payload.contentAssistanceEnabled;
      return draft;

    case UPDATE_CONTENT_ASSISTANT_ENABLED:
      draft.contentAssistanceEnabled = action.payload.contentAssistanceEnabled;
      return draft;

    default:
      return draft;
  }
});
export default contentAssistantReducer;