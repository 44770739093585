import getPurchaseOrderId, { getMultipleOfferPurchaseOrderId } from 'self-service-api/api/getPurchaseOrderId';
export const generatePurchaseOrder = (currencyCode, productConfigurations, products, offerId, productBasedOffers) => {
  const purchaseConfig = {
    offerId,
    currencyCode,
    productConfigurations,
    productBasedOffers
  };
  return getPurchaseOrderId(purchaseConfig, products);
};
export const generateMultipleOfferPurchaseOrder = (currencyCode, offerProductConfigurations, nonRecurringProductConfigurations, products, productBasedOffers, canCheckoutRecurringSkusAsIncrease = false) => {
  const purchaseConfig = {
    offerProductConfigurations,
    nonRecurringProductConfigurations,
    currencyCode,
    productBasedOffers
  };
  return getMultipleOfferPurchaseOrderId(purchaseConfig, products, canCheckoutRecurringSkusAsIncrease);
};