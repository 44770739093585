import http from 'hub-http/clients/apiClient';
import { getAssetKeyFromAsset } from '../utils/multipleAssets';
const BASE_URL = 'commenting/v2/comments';
export const updateCommentMetaDescriptionOptions = (id, updatedMetaDescription) => http.patch(`${BASE_URL}/${id}`, {
  data: [{
    op: 'replace',
    path: '/metaDescription',
    value: updatedMetaDescription
  }]
});
const fetchCommentsPromises = {};
export const fetchComments = params => http.get(BASE_URL, {
  query: Object.assign({
    // The default limit on the BE is 200,
    // We need this to be a high number so we can
    // make sure we fetch all top level comments and all replies.
    // 500 is the maximum limit we can set.
    limit: 500
  }, params)
});
export const fetchCommentsCached = params => {
  const {
    objectType,
    objectId,
    refetch
  } = params;
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });

  if (!fetchCommentsPromises[key] || refetch) {
    fetchCommentsPromises[key] = fetchComments({
      objectType,
      objectId
    });
  }

  return fetchCommentsPromises[key];
};
export const updateCommentsCache = ({
  objectType,
  objectId,
  comments
}) => {
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });

  if (key in fetchCommentsPromises) {
    fetchCommentsPromises[key] = Promise.resolve({
      results: comments,
      total: comments.length
    });
  }
};

const fetchAssetLastUpdatedAt = ({
  objectType,
  objectId
}) => http.get(`commenting/v2/objects/${objectType}/${objectId}/last-updated-at`);

export const fetchAssetLastUpdatedAtOrDefault = (...args) => fetchAssetLastUpdatedAt(...args).then(res => res, e => {
  if (e.status === 404) {
    // no comments on asset
    return {
      updatedAt: new Date(0).toISOString()
    };
  }

  throw e;
});