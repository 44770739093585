'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import TemplateTypes from 'ContentUtils/constants/TemplateTypes';
import I18n from 'I18n';
const developerGetSectionsEndpoint = 'designmanager/v1/sections/coded';
const oldCrudSectionsEndpoint = 'designmanager/v1/sections';
const newCrudSectionsEndpoint = 'content-editor/v1/marketer-section'; // TODO: These are both paginated endpoints, need to figure this out

export function fetchAddableSections(themePath, templatePath) {
  return Promise.all([http.get(developerGetSectionsEndpoint, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get(),
      templateTypeId: TemplateTypes.byName.section.id,
      themePath,
      locale: I18n.lang,
      limit: 1000,
      templatePath
    }
  }).then(devSections => {
    devSections.objects.forEach(section => {
      section.marketerSection = false;
    });
    return devSections;
  }), http.get(newCrudSectionsEndpoint, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get(),
      templateTypeId: TemplateTypes.byName.section.id,
      themePath,
      locale: I18n.lang,
      limit: 1000
    }
  }).then(marketerSections => {
    marketerSections.objects.forEach(section => {
      section.marketerSection = true;
    });
    return marketerSections;
  })]).then(([devSections, marketerSections]) => {
    return {
      allSections: [...marketerSections.objects, ...devSections.objects],
      marketerSectionsCount: marketerSections.objects.length,
      marketerSectionsLimit: marketerSections.themeLimit
    };
  });
}
export function saveNewSection({
  name,
  description,
  sectionTree,
  screenshotUrl,
  themePath,
  isSmartSection
}, isUngatedForNewSectionsEndpoints) {
  const crudSectionsEndpointToUse = isUngatedForNewSectionsEndpoints ? newCrudSectionsEndpoint : oldCrudSectionsEndpoint;
  return http.post(crudSectionsEndpointToUse, {
    timeout: 15000,
    data: {
      name,
      description,
      sectionTree,
      screenshotUrl,
      themePath,
      isSmart: isSmartSection
    }
  });
}
export function deleteSection(sectionId, isUngatedForNewSectionsEndpoints) {
  const crudSectionsEndpointToUse = isUngatedForNewSectionsEndpoints ? newCrudSectionsEndpoint : oldCrudSectionsEndpoint;
  const url = `${crudSectionsEndpointToUse}/${sectionId}`;
  return http.delete(url, {
    timeout: 15000
  });
}
export function fetchMarketerSections(themePath) {
  return http.get(newCrudSectionsEndpoint, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get(),
      templateTypeId: TemplateTypes.byName.section.id,
      themePath,
      locale: I18n.lang,
      limit: 1000
    }
  });
}