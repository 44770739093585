'use es6';

export const FETCH_CONTENT_SCHEMA_REQUEST = 'FETCH_CONTENT_SCHEMA_REQUEST';
export const FETCH_MODULE_SCHEMAS_REQUEST = 'FETCH_MODULE_SCHEMAS_REQUEST';
export const FETCH_SUPPORTED_LANGUAGES_REQUEST = 'FETCH_SUPPORTED_LANGUAGES_REQUEST';
export const CONTENT_SAVE_REQUEST = 'CONTENT_SAVE_REQUEST';
export const CONTENT_PUBLISH_REQUEST = 'CONTENT_PUBLISH_REQUEST';
export const FETCH_LAYOUT_DATA_REQUEST = 'FETCH_LAYOUT_DATA_REQUEST';
export const FETCH_TEMPLATE_INFO_REQUEST = 'FETCH_TEMPLATE_INFO_REQUEST';
export const FETCH_REVISIONS_REQUEST = 'FETCH_REVISIONS_REQUEST';
export const FETCH_SCHEDULED_VERSION_REQUEST = 'FETCH_SCHEDULED_VERSION_REQUEST';
export const FETCH_THEME_REQUEST = 'FETCH_THEME_REQUEST';
export const FETCH_CUSTOM_FONTS_REQUEST = 'FETCH_CUSTOM_FONTS_REQUEST';
export const FETCH_EDITABLE_SLUG_INFO_REQUEST = 'FETCH_EDITABLE_SLUG_INFO_REQUEST';
export const FETCH_PORTAL_SCOPES_REQUEST = 'FETCH_PORTAL_SCOPES_REQUEST';
export const FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST = 'FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST';
export const FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST = 'FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_INITIAL_USER_ATTRIBUTES_REQUEST = 'FETCH_INITIAL_USER_ATTRIBUTES_REQUEST';
export const FETCH_MODULE_ERRORS_REQUEST = 'FETCH_MODULE_ERRORS_REQUEST';
export const FETCH_CUSTOM_DOMAINS_PURGE_REQUEST = 'FETCH_CUSTOM_DOMAINS_PURGE_REQUEST';
export const FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST = 'FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST';
export const FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST = 'FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST';
export const FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST = 'FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST';
export const FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST = 'FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST'; // PAGE ONLY TODO branden prob move this to peui

export const FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST = 'FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST';
export const FETCH_HUBDB_TABLES_REQUEST = 'FETCH_HUBDB_TABLES_REQUEST';
export const FETCH_DATA_QUERIES_REQUEST = 'FETCH_DATA_QUERIES_REQUEST';
export const FETCH_CONTACT_LISTS_REQUEST = 'FETCH_CONTACT_LISTS_REQUEST';
export const FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST = 'FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST';
export const FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST = 'FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST';
export const FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST = 'FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST';
export const FETCH_MARKETER_SECTIONS_REQUEST = 'FETCH_MARKETER_SECTIONS_REQUEST';
export const FETCH_DOMAIN_EXCLUSION_REASON = 'FETCH_DOMAIN_EXCLUSION_REASON';
export const FETCH_GENERATED_LAYOUT_HUBL_REQUEST = 'FETCH_GENERATED_LAYOUT_HUBL_REQUEST';
export const FETCH_TEMPLATE_SCHEMA_REQUEST = 'FETCH_TEMPLATE_SCHEMA_REQUEST';
export const FETCH_OPTIMIZATIONS_REQUEST = 'FETCH_OPTIMIZATIONS_REQUEST';
export const FETCH_CUSTOM_SECTIONS_REQUEST = 'FETCH_CUSTOM_SECTIONS_REQUEST';
export const FETCH_CMS_SETTINGS_REQUEST = 'FETCH_CMS_SETTINGS_REQUEST';