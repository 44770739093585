import { useEffect, useState } from 'react';
import usePrevious from './usePrevious';
import { UsageTracker } from 'collaboration-sidebar/utils/usageTracker';
export default function useTrackLiveRefresh({
  unreadCommentsCount: count,
  unreadCommentsLoading: loading
}) {
  const [hasLoadedInitialCount, setHasLoadedInitialCount] = useState(false);
  const prevCount = usePrevious(count);
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (prevLoading && !loading) {
      setHasLoadedInitialCount(true);
    }
  }, [prevLoading, loading]);

  if (hasLoadedInitialCount && count > prevCount) {
    UsageTracker.track('collabSidebarInteraction', {
      action: 'New comments while viewing asset'
    });
  }
}