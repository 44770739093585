/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import _ from 'underscore';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import Backbone from 'Backbone';
import actionTypes from 'ContentEditorUI/redux/actions/inpageActionTypes';
import logSeleniumEvent from 'ContentEditorUI/utils/logSeleniumEvent';
import { getUsageTracker } from 'tinymce-plugins/utils/usageTracking';
export default class OldIndexControllerBusEventHandlers {
  constructor(reduxStore, saveActions, bus) {
    this.onSaveContainers = this.onSaveContainers.bind(this);
    this.saveAndTriggerRefresh = this.saveAndTriggerRefresh.bind(this);
    this.onLogSeleniumEvent = this.onLogSeleniumEvent.bind(this);
    this.onLogLayoutSectionEvent = this.onLogLayoutSectionEvent.bind(this);
    this.bus = bus;
    this.reduxStore = reduxStore;
    this.saveActions = saveActions;

    _(this).extend(Backbone.Events);
  }

  registerEvents() {
    const busEvents = this.busEvents();
    Object.keys(busEvents).forEach(eventName => {
      const eventHandler = busEvents[eventName];
      this.listenTo(this.bus, eventName, eventHandler, this);
    });
  }

  unregisterEvents() {
    const busEvents = this.busEvents();
    Object.keys(busEvents).forEach(eventName => {
      const eventHandler = busEvents[eventName];
      this.stopListening(this.bus, eventName, eventHandler, this);
    });
  }

  busEvents() {
    return {
      'post:saveContainers': this.onSaveContainers,
      [`post:${actionTypes.LOG_LAYOUT_SECTION_EVENT}`]: this.onLogLayoutSectionEvent,
      [`post:${actionTypes.SAVE_AND_REFRESH_PREVIEW}`]: this.saveAndTriggerRefresh,
      [`post:${actionTypes.LOG_SELENIUM_EVENT}`]: this.onLogSeleniumEvent,
      'post:trackMceEvent': this.trackTinyMceUsage,
      saveAndTriggerRefresh: this.saveAndTriggerRefresh
    };
  }

  registerAllLegacyEvents() {
    return this.registerEvents();
  } // Note, I removed the previous `stop()` function because I was pretty sure it was never called
  // (it seemed like the IndexController was initialized once and never undone, so no need for
  // unregistering events, etc)
  // Preview/iframe events
  // Event when a module is added/removed/moved to a container OR when a module is moved around
  // in the "layout mode" of the preview


  onSaveContainers(message) {
    if (message.forceRefresh) {
      this.saveActions.autoSave().then(() => window.hubspot.ContentEditorUI.eventBus.trigger('preview:refresh'));
    }
  }

  saveAndTriggerRefresh(opts) {
    this.saveActions.autoSave(opts).then(() => window.hubspot.ContentEditorUI.eventBus.trigger('preview:refresh', {
      forceReload: true
    }));
  }

  onLogSeleniumEvent(msg) {
    logSeleniumEvent(msg.event, msg.data);
  } // Other event handlers purely needed for usage tracking


  onLogLayoutSectionEvent(message) {
    UsageTracker.trackEditorInteraction(message.data);
  }

  trackTinyMceUsage(message) {
    const appName = message.inApp || UsageTracker.getAppName();
    const tinyMceUsageTracker = getUsageTracker(UsageTracker.getCachedClonedTracker('richText'), appName);
    tinyMceUsageTracker.track(message.event.name, message.event.meta);
  }

}