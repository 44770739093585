import { combineReducers } from 'redux';
import { CRM_OBJECTS_CLEAR_CACHE, CRM_OBJECTS_FETCH_OBJECT_SCHEMA, CRM_OBJECTS_FETCH_OBJECT_TYPES, CRM_OBJECTS_FETCH_OBJECT, CRM_OBJECTS_FETCH_OBJECTS, CRM_OBJECTS_SAVE_OBJECT_CACHE, CRM_OBJECTS_SAVE_PROPERTY_CACHE } from 'ContentData/actions/ActionTypes';
import { PENDING, SUCCEEDED, FAILED, UNINITIALIZED } from 'ContentUtils/constants/RequestStatus';
import { CrmObjectMetaTypes } from 'ContentUtils/enums/CrmObjectMetaTypes';
import { CrmObjectTypes } from 'ContentUtils/constants/CrmObjectTypes';

function flattenObjectProperties(crmObject) {
  const {
    properties = {}
  } = crmObject || {};
  const newProperties = Object.keys(properties).reduce((acc, prop) => {
    const propObject = properties[prop];
    acc[prop] = propObject.value;
    return acc;
  }, {});
  return Object.assign({}, crmObject, {
    properties: newProperties
  });
}

function crmObjectTypes(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECT_TYPES:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const filteredObjectTypes = response.filter(type => {
          const isBuiltinType = type.metaType === CrmObjectMetaTypes.HUBSPOT;
          const isProductType = isBuiltinType && type.name.toLowerCase() === CrmObjectTypes.PRODUCT; // Allow product object type to be selected despite it missing its
          // primaryDisplayLabelPropertyName for the time being

          if (!type.primaryDisplayLabelPropertyName && !isProductType) return false;

          if (isBuiltinType) {
            const objectTypeKey = type.name.toUpperCase();
            return !!CrmObjectTypes[objectTypeKey];
          }

          return true;
        });
        return filteredObjectTypes.reduce((map, type) => {
          map[type.fullyQualifiedName] = type;
          return map;
        }, {});
      }

    default:
      {
        return state;
      }
  }
}

function crmObjectSchemas(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECT_SCHEMA:
      {
        const {
          options: objectType,
          response
        } = action;
        if (!(objectType && response)) return state;
        return Object.assign({}, state, {
          [objectType]: response
        });
      }

    default:
      return state;
  }
}

function selectedCrmObjectProperties(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_SAVE_PROPERTY_CACHE:
      {
        const {
          objectType,
          options,
          property
        } = action;
        if (!objectType || !options || !property) return state;
        const properties = state[objectType] || {};
        if (properties[property]) return state;
        return Object.assign({}, state, {
          [objectType]: Object.assign({}, properties, {
            [property]: options.find(option => option.value === property)
          })
        });
      }

    default:
      return state;
  }
}

function crmObjects(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECT:
      {
        const {
          response,
          options
        } = action;
        const {
          objectType
        } = options || {};
        if (!response || !objectType) return state;
        const objects = state[objectType] || {};
        const crmObject = flattenObjectProperties(response);
        const properties = objects[crmObject.objectId] ? objects[crmObject.objectId].properties : {};
        crmObject.properties = Object.assign({}, properties, {}, crmObject.properties);
        return Object.assign({}, state, {
          [objectType]: Object.assign({}, objects, {
            [crmObject.objectId]: crmObject
          })
        });
      }

    case CRM_OBJECTS_SAVE_OBJECT_CACHE:
      {
        const {
          crmObject,
          objectType
        } = action;
        if (!crmObject || !objectType) return state;
        const objects = state[objectType] || {};
        return Object.assign({}, state, {
          [objectType]: Object.assign({}, objects, {
            [crmObject.objectId]: crmObject
          })
        });
      }

    default:
      return state;
  }
}

function crmObjectsCache(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECTS:
      {
        const {
          response,
          options
        } = action;
        const {
          objectType
        } = options || {};
        if (!(objectType && response && response.results)) return state;
        const objects = state[objectType] || {};
        return Object.assign({}, state, {
          [objectType]: response.results.reduce((hash, object) => {
            const crmObject = flattenObjectProperties(object);
            hash[crmObject.objectId] = crmObject;
            return hash;
          }, Object.assign({}, objects))
        });
      }

    case CRM_OBJECTS_CLEAR_CACHE:
      {
        const {
          objectType
        } = action;
        const objects = state[objectType] || {};
        if (Object.keys(objects).length) return Object.assign({}, state, {
          [objectType]: {}
        });
        return state;
      }

    default:
      return state;
  }
}

function crmObjectsCacheRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECTS:
      if (action.response) return SUCCEEDED;
      if (action.error) return FAILED;
      return PENDING;

    default:
      return state;
  }
}

function crmObjectsCacheOrder(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECTS:
      {
        const {
          response,
          options
        } = action;
        const {
          objectType
        } = options || {};
        if (!(objectType && response && response.results)) return state;
        const cacheOrder = state[objectType] || [];
        return Object.assign({}, state, {
          [objectType]: response.results.reduce((hash, object) => {
            hash.push(object.objectId);
            return hash;
          }, [...cacheOrder])
        });
      }

    case CRM_OBJECTS_CLEAR_CACHE:
      {
        const {
          objectType
        } = action;
        const cacheOrder = state[objectType] || [];
        if (cacheOrder.length) return Object.assign({}, state, {
          [objectType]: []
        });
        return state;
      }

    default:
      return state;
  }
}

function requestStatus(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECT:
      {
        const {
          response,
          error,
          options
        } = action;
        const {
          objectType,
          id
        } = options || {};
        if (!objectType) return state;
        let newRequestStatus = PENDING;
        if (response) newRequestStatus = SUCCEEDED;
        if (error) newRequestStatus = FAILED;
        const requestStatuses = state[objectType];
        return Object.assign({}, state, {
          [objectType]: Object.assign({}, requestStatuses, {
            [id]: newRequestStatus
          })
        });
      }

    default:
      return state;
  }
}

function schemaRequestStatus(state = {}, action) {
  switch (action.type) {
    case CRM_OBJECTS_FETCH_OBJECT_SCHEMA:
      {
        const {
          options: objectType,
          response,
          error
        } = action;
        if (!objectType) return state;
        let newRequestStatus = PENDING;
        if (response) newRequestStatus = SUCCEEDED;
        if (error) newRequestStatus = FAILED;
        return Object.assign({}, state, {
          [objectType]: newRequestStatus
        });
      }

    default:
      return state;
  }
}

export default combineReducers({
  crmObjects,
  crmObjectsCache,
  crmObjectsCacheOrder,
  crmObjectsCacheRequestStatus,
  crmObjectSchemas,
  crmObjectTypes,
  requestStatus,
  schemaRequestStatus,
  selectedCrmObjectProperties
});