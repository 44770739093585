'use es6';

import { createSelector } from 'reselect';
import { List, Map as ImmutableMap } from 'immutable';
import { getActiveDomainName, getContentModelDomain } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContextActiveDomainAsMap, getContextPrimaryDomain, getContextSecondaryToDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { createHasScopeSelector, getIsProductIncompatibleWithPrerendering } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getAllowDomainChangeForNinetyDaysAfterDowngrade } from 'ContentEditorUI/redux/selectors/portalSelectors';
import { domainIsLegacy, domainIsStaging, domainIsFreePageBuilder } from 'ContentEditorUI/utils/domainsUtils';
import { getSiteSettings } from 'ContentEditorUI/redux/selectors/siteSettingsSelectors';
import { getIsDomainChangeAllowedAfterDowngrades, getIsFreePagesPortal } from './downgradeSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsLandingOrSitePage } from './contentReadOnlyDataSelectors';
import { getMembershipSsoEnabledForDomain as getMembershipSsoEnabledForDomainUtil } from 'ContentUtils/helpers/AudienceAccessHelpers'; //
// Selectors
//

export const getDomainsState = basicSelector(state => state.portalDomains);
export const getAvailableDomains = createSelector([getDomainsState], domainsState => domainsState.get('domains'));
export const getDomainExclusionReason = createSelector([getDomainsState], domainsState => domainsState.get('domainExclusionReason'));
export const getHasMultiDomainScope = createHasScopeSelector('multi-domain-publishing');
export const getHasContentCustomDomainWrite = createHasScopeSelector('content-custom-domain-write');
export const getAvailableStagingDomains = createSelector([getAvailableDomains], availableDomains => {
  return availableDomains.filter(d => {
    return domainIsStaging(d);
  });
});
export const getDomainsNotLegacyOrStaging = createSelector([getAvailableDomains], availableDomains => availableDomains.filter(domain => {
  return !(domainIsStaging(domain) || domain.get('isStagingDomain') || // for more details about this confusing condition read here: https://git.hubteam.com/HubSpot/ContentEditorUI/pull/8945#discussion_r1006357
  domainIsLegacy(domain));
})); // Gets the domain object of the domain content model or
// the active domain object that is returned from context onload if not set

export const getActiveDomain = createSelector([getContentModelDomain, getContextActiveDomainAsMap, getAvailableDomains], (contentModelDomainName, contextActiveDomain, domains) => {
  if (contentModelDomainName) {
    const contentModelDomain = domains.find(d => d.get('domain') === contentModelDomainName);

    if (contentModelDomain) {
      return contentModelDomain;
    }
  }

  return contextActiveDomain;
});
export const getActiveApexDomain = createSelector(getActiveDomain, activeDomain => {
  if (!activeDomain) {
    return null;
  }

  return activeDomain.get('apexDomain') || activeDomain.get('apex_domain');
});
export const getActiveDomainIsConnected = createSelector([getActiveDomain], activeDomain => // Have to account for context active domain (snake case) and
// domain object from domains list (camel case)
(activeDomain.get('isResolving') || activeDomain.get('is_resolving')) && (activeDomain.get('isDnsCorrect') || activeDomain.get('is_dns_correct')));
export const getActiveDomainIsInternal = createSelector([getActiveDomain], activeDomain => activeDomain.has('isInternalDomain') ? activeDomain.get('isInternalDomain') : activeDomain.get('is_internal_domain'));
export const getActiveDomainIsConnectedAndExternal = createSelector([getActiveDomainIsInternal, getActiveDomainIsConnected], (isActiveDomainInternal, isActiveDomainConnected) => !isActiveDomainInternal && isActiveDomainConnected); // This also determines whether this is a staging page or not

export const getActiveStagingDomain = createSelector([getAvailableStagingDomains, getActiveDomainName], (availableStagingDomains, activeDomainName) => availableStagingDomains.find(domain => domain.get('domain') === activeDomainName));
export const getFullDomainPath = createSelector([getAvailableDomains, getActiveDomainName, getContextActiveDomainAsMap], (availableDomains, contentModalDomain, contextActiveDomain) => {
  let domain = availableDomains.find(availableDomain => availableDomain.get('domain') === contentModalDomain);

  if (!domain) {
    domain = contextActiveDomain;
  }

  const protocol = domain && (domain.get('is_ssl_only') || domain.get('isSslOnly')) ? 'https://' : 'http://';
  return `${protocol}${domain.get('domain')}`;
});
export const getPagePrimaryDomain = createSelector([getContextPrimaryDomain], primaryDomain => ImmutableMap(primaryDomain));
export const getPageSecondaryToDomain = createSelector([getContextSecondaryToDomain], secondaryToDomain => ImmutableMap(secondaryToDomain));
export const getFreePageBuilderDomain = createSelector(getDomainsNotLegacyOrStaging, domainsNotLegacyOrStaging => domainsNotLegacyOrStaging.filter(domainIsFreePageBuilder).first());
export const getActiveStagingOrPagePrimaryDomain = createSelector([getActiveStagingDomain, getPagePrimaryDomain], (activeStagingDomain, pagePrimaryDomain) => activeStagingDomain || pagePrimaryDomain);
export const getIsPageOnPagebuilderDomain = createSelector([getActiveDomain], activeDomain => domainIsFreePageBuilder(activeDomain)); // To warn customers that they should not publish pages on pagebuilder domain
// because they have access to features which may be incompatible with pre-rendering

export const getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct = createSelector([getIsPageOnPagebuilderDomain, getIsProductIncompatibleWithPrerendering, getIsLandingOrSitePage], (isPageOnPagebuilderDomain, isProductIncompatibleWithPrerendering, isLandingOrSitePage) => isLandingOrSitePage && isPageOnPagebuilderDomain && isProductIncompatibleWithPrerendering);

const addContextActiveDomainToDomains = (domains, contextActiveDomain) => {
  const contextActiveDomainName = contextActiveDomain.get('domain'); // Add the context active domain (domain content is assigned on load)
  // to the options always if it isn't already there

  if (domains.some(d => d.get('domain') === contextActiveDomainName)) {
    return domains;
  }

  return domains.unshift(contextActiveDomain);
};

const _getAvailableStagingDomainsForPage = createSelector([getHasMultiDomainScope, getActiveStagingDomain, getAvailableStagingDomains, getContextActiveDomainAsMap], (hasMultiDomainScope, activeStagingDomain, availableStagingDomains, contextActiveDomain) => hasMultiDomainScope ? addContextActiveDomainToDomains(availableStagingDomains, contextActiveDomain) : List([activeStagingDomain]) // Users cannot change domain for staged pages when they do not have multi-domain access
);

const _getAvailableDomainsForPage = createSelector([getHasMultiDomainScope, getAllowDomainChangeForNinetyDaysAfterDowngrade, getDomainsNotLegacyOrStaging, getPagePrimaryDomain, getActiveDomainName, getContextActiveDomainAsMap], (hasMultiDomainScope, allowDomainChangeAfterDowngrade, domainsNotLegacyOrStaging, pagePrimaryDomain, contentModelDomain, contextActiveDomain) => {
  let availableDomainOptions;

  if (hasMultiDomainScope || allowDomainChangeAfterDowngrade) {
    availableDomainOptions = domainsNotLegacyOrStaging;
  } else {
    availableDomainOptions = domainsNotLegacyOrStaging.filter(d => {
      return d.get('domain') === contentModelDomain || d.get('domain') === pagePrimaryDomain.get('domain');
    });
  }

  return addContextActiveDomainToDomains(availableDomainOptions, contextActiveDomain);
});

export const getAvailableDomainsForPage = createSelector([getActiveStagingDomain, _getAvailableStagingDomainsForPage, _getAvailableDomainsForPage], (activeStagingDomain, availableStagingDomainsForPage, availableDomainOptions) => {
  if (activeStagingDomain) {
    return availableStagingDomainsForPage;
  }

  return availableDomainOptions;
});
export const getAvailableConnectedDomainsForPageCount = createSelector([getAvailableDomainsForPage], availableDomains => {
  return availableDomains.filter(domain => domain.get('is_internal_domain') === false).size;
});
export const getIsViolatingMultiDomainAccess = createSelector([getActiveDomainName, getPagePrimaryDomain, getActiveStagingDomain, getHasMultiDomainScope, getIsFreePagesPortal], (contentModelDomain, pagePrimaryDomain, activeStagingDomain, hasMultiDomainScope, isFreePagesPortal) => {
  if (!hasMultiDomainScope) {
    if (isFreePagesPortal) {
      return false;
    }

    if (activeStagingDomain) {
      return false;
    }

    if (contentModelDomain && pagePrimaryDomain) {
      return contentModelDomain !== pagePrimaryDomain.get('domain');
    }
  }

  return false;
}); // Invalid domain is when on load, the content model has a domain that is
// not the same as context.active_domain. We can compute if it's invalid
// bc if it's not in the result of getAvailableDomainsForPage (which includes
// active_domain), then the domain doesn't exist. This is opposed to a normal
// situation where the active_domain/content domain is a domain that is not
// included in the domains response, which is valid, but the user can't publish.

export const getContentModelHasValidDomain = createSelector([getContentModelDomain, getAvailableDomainsForPage], (contentModelDomain, allValidDomainsForPage) => !contentModelDomain || allValidDomainsForPage.some(d => d.get('domain') === contentModelDomain)); // Check if current domain is not permitted for this user to publish on

export const getCurrentDomainIsNotPermitted = createSelector([getActiveDomainName, getAvailableDomains, getContentModelHasValidDomain], (activeDomainName, availableDomains, contentModelHasValidDomain) => // Want to avoid showing unauthorized error if content model has invalid
// domain, so that we can display a validation error instead for them to fix
contentModelHasValidDomain && !availableDomains.some(d => d.get('domain') === activeDomainName));
export const getMembershipSsoEnabledForDomain = createSelector([getActiveDomainName, getSiteSettings], (domain, settings) => getMembershipSsoEnabledForDomainUtil(domain, settings));
export const getCanOnlyChangeToPrimaryDomain = createSelector([getIsDomainChangeAllowedAfterDowngrades, getHasMultiDomainScope], (isDomainChangeAllowedAfterDowngrades, hasMultiDomainScope) => !isDomainChangeAllowedAfterDowngrades && !hasMultiDomainScope);