import { useSelector } from 'react-redux';
import { getGates } from '../selectors/auth';
import { useMemo } from 'react';
export const useGates = gatesSelector => {
  const gates = useSelector(gatesSelector || getGates);
  return useMemo(() => {
    let isUngatedForActivityFeed = false;
    let isUngatedForAnyoneCanApproveRule = false;
    gates.forEach(gate => {
      if (gate === 'ProductApprovals:ActivityFeed') {
        isUngatedForActivityFeed = true;
      }

      if (gate === 'ProductApprovals:AnyoneCanApproveRule') {
        isUngatedForAnyoneCanApproveRule = true;
      }
    });
    return {
      isUngatedForActivityFeed,
      isUngatedForAnyoneCanApproveRule
    };
  }, [gates]);
};