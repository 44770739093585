module.exports = {
  "externalLinkClick": {
    "name": "external-link-click",
    "class": "interaction",
    "properties": {
      "trackingSource": {
        "type": "string",
        "isOptional": true
      },
      "name": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "communicationMethodsInteraction": {
    "name": "communication-methods",
    "class": "interaction",
    "properties": {
      "isRetail": {
        "type": "boolean",
        "isOptional": true
      },
      "meetingLink": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "contactSalesButtonInteraction": {
    "name": "contact-sales-button",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "termId": {
        "type": "number",
        "isOptional": true
      },
      "additionalUnitQuantity": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "lockedFeatureInteraction": {
    "name": "locked-feature",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "pricingPageRedirectButtonInteraction": {
    "name": "pricing-page-redirect-button",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "productActivationButtonInteraction": {
    "name": "product-activation-button",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "productActivationInteraction": {
    "name": "product-activation",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "trialActivationButtonInteraction": {
    "name": "trial-activation-button",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialActivationInteraction": {
    "name": "trial",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialActionPromptInteraction": {
    "name": "trial-action-prompt",
    "class": "interaction",
    "properties": {
      "numUsers": {
        "type": "number",
        "isOptional": true
      },
      "numContacts": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialModalInteraction": {
    "name": "trial-modal",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialAlertBannerInteraction": {
    "name": "trial-alert-banner",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "trialActivationRedirected": {
    "name": "trial-activation-redirected",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "redirectTarget": "string",
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "viewUpgradeAdminPrompt": {
    "name": "view-upgrade-admin-prompt",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "modalType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "checkoutButtonInteraction": {
    "name": "checkout-button",
    "class": "interaction",
    "properties": {
      "currencyCode": {
        "type": "string"
      },
      "offerId": {
        "type": "number",
        "isOptional": true
      },
      "isQuantityUpgrade": {
        "type": "boolean",
        "isOptional": true
      },
      "productNames": {
        "type": "array",
        "isOptional": true
      },
      "offerIds": {
        "type": "array",
        "isOptional": true
      },
      "skuIds": {
        "type": "array",
        "isOptional": true
      },
      "productBasedOfferIds": {
        "type": "array",
        "isOptional": true
      },
      "checkoutType": [
        "embedded",
        "full"
      ],
      "additionalUnitQuantity": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "errorInteraction": {
    "name": "error",
    "class": "interaction",
    "properties": {
      "name": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "pqlInteraction": {
    "name": "pql",
    "class": "interaction",
    "properties": {
      "isAssignable": {
        "type": "boolean",
        "isOptional": true
      },
      "isRetail": {
        "type": "boolean",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "requestUpgradeInteraction": {
    "name": "request-upgrade",
    "class": "interaction",
    "properties": {
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "addAdditionalSeatsClick": {
    "name": "add-additional-seats-click",
    "class": "interaction",
    "properties": {
      "trackingSource": "string",
      "isValid": "boolean",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "isAssignable": {
        "type": "boolean",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "value": {
        "type": "number",
        "isOptional": true
      },
      "displayThreshold": {
        "type": "number",
        "isOptional": true
      },
      "hasReachedLimit": {
        "type": "boolean",
        "isOptional": true
      },
      "isPrimaryCta": {
        "type": "boolean",
        "isOptional": true
      },
      "isSecondaryCta": {
        "type": "boolean",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "purchaseMotionEligibility": {
        "type": "array",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "modalUpgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      },
      "primaryColor": {
        "type": "string",
        "isOptional": true
      },
      "wordpressPlugin": {
        "type": "string",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "subtitle": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  }
};