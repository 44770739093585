import { SECTIONS_SIDEBAR_WIDTH, LAYOUT_SIDE_BAR_WIDTH, MODULES_CATEGORIES_SEARCH_SIDEBAR_WIDTH, SECTIONS_SEARCH_SIDEBAR_WIDTH, MODULES_CATEGORIES_SIDEBAR_WIDTH } from 'ContentEditorUI/lib/sidebar/constants';
import { PATAGONIA_ADD_PANEL_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
export const getDefaultAddPanelContentWidth = (selectedCategory, isInAllSubCategoriesSearch = false) => {
  switch (selectedCategory) {
    case PATAGONIA_ADD_PANEL_CATEGORIES.MODULES:
      return isInAllSubCategoriesSearch ? MODULES_CATEGORIES_SEARCH_SIDEBAR_WIDTH : MODULES_CATEGORIES_SIDEBAR_WIDTH;

    case PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS:
      return isInAllSubCategoriesSearch ? SECTIONS_SEARCH_SIDEBAR_WIDTH : SECTIONS_SIDEBAR_WIDTH;

    case PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS:
      return LAYOUT_SIDE_BAR_WIDTH;

    default:
      throw new Error(`Bad implementation of SidebarContainer, ${selectedCategory} is not a valid option.`);
  }
};
//TODO type section when migrate sectionsReducer to immer
export const shouldShowSection = (searchQuery, section) => {
  const sectionLabel = section.displayName || section.label || section.name;
  const sectionDescription = section.description || '';
  const sectionLabelToTest = sectionLabel.toLowerCase();
  const sectionDescriptionToTest = sectionDescription.toLowerCase();
  const lowerCaseSearchQuery = searchQuery.toLowerCase();
  return sectionLabelToTest.indexOf(lowerCaseSearchQuery) !== -1 || sectionDescriptionToTest.indexOf(lowerCaseSearchQuery) !== -1;
};