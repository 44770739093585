import { fetchContentAssistanceSettings } from 'ContentEditorUI/api/ContentAssistantApi';
import { GET_CONTENT_ASSISTANT_SETTINGS, UPDATE_CONTENT_ASSISTANT_ENABLED } from 'ContentEditorUI/redux/actions/actionTypes';
export const getContentAssistantSettings = () => dispatch => {
  return fetchContentAssistanceSettings().then(response => {
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: response
    });
  }) // fallback to contentAssistanceEnabled=false when request fails
  .catch(() => {
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: {
        contentAssistanceEnabled: false
      }
    });
  });
};
export const updateContentAssistantEnabled = value => {
  return {
    type: UPDATE_CONTENT_ASSISTANT_ENABLED,
    payload: {
      contentAssistanceEnabled: value
    }
  };
};