'use es6';

import _, { any } from 'underscore';
import { createSelector } from 'reselect';
import States from 'ContentUtils/constants/States';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { PublicAccessRuleTypes } from 'ContentUtils/constants/PublicAccessRuleTypes';
import { getContextActiveDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getContentModel = basicSelector(state => state.contentModel);
export const createContentModelSelector = field => createSelector([getContentModel], contentModel => contentModel.get(field));
export const getActiveDomainName = createSelector([getContentModel, getContextActiveDomain], (contentModel, activeDomain) => {
  return contentModel.get('domain') || activeDomain.domain;
});
export const getActiveApexDomainName = createSelector([getContextActiveDomain], activeDomain => activeDomain.apexDomain);
export const getContentModelCampaign = createSelector([getContentModel], contentModel => {
  return {
    campaign: contentModel.get('campaign'),
    campaignName: contentModel.get('campaignName')
  };
});
export const getHasContentModelCampaign = createSelector([getContentModelCampaign], contentModelCampaign => {
  return contentModelCampaign && (contentModelCampaign.campaign || contentModelCampaign.campaignUtm);
});
export const getHasContentModel = createSelector([getContentModel], contentModel => contentModel.get('id') != null);
export const getContentModelDomain = createContentModelSelector('domain');
export const getContentModelHtmlTitle = createContentModelSelector('htmlTitle');
export const getContentModelId = createContentModelSelector('id');
export const getContentModelIsPublished = createContentModelSelector('isPublished');
export const getContentModelMetaDescription = createContentModelSelector('metaDescription');
export const getContentModelName = createContentModelSelector('name');
export const getContentModelSlug = createContentModelSelector('slug');
export const getContentModelCanonicalUrl = createSelector([getContentModel], contentModel => contentModel.get('linkRelCanonicalUrl') || '');
export const getContentModelPageExpiryDate = createContentModelSelector('pageExpiryDate');
export const getContentModelPageExpiryEnabled = createContentModelSelector('pageExpiryEnabled');
export const getContentModelPageExpiryRedirectId = createContentModelSelector('pageExpiryRedirectId');
export const getContentModelPageExpiryRedirectUrl = createContentModelSelector('pageExpiryRedirectUrl');
export const getPreviewKey = createContentModelSelector('previewKey');
export const getContentState = createContentModelSelector('state'); // This is a substring search helper for the state selector below

const matchesState = (check, currentState) => {
  const allStates = _.values(States);

  const roughMatches = _(allStates).filter(state => state.lastIndexOf(check) > -1);

  return _(roughMatches).contains(currentState);
};

export const stateSelector = check => {
  return createSelector([getContentState], state => {
    if (check instanceof Array) {
      return any(check.map(item => matchesState(item, state)));
    }

    return matchesState(check, state);
  });
};
export const getContentAbTestPercentage = createContentModelSelector('abTestPercentage');
export const getContentAbSuccessMetric = createContentModelSelector('abSuccessMetric');
export const getContentAbHoursToWait = createContentModelSelector('abHoursToWait');
export const getContentAbSampleSizeDefault = createContentModelSelector('abSampleSizeDefault');
export const getContentAbSamplingDefault = createContentModelSelector('abSamplingDefault');
export const getSubcategory = createContentModelSelector('subcategory');
export const subcategorySelector = check => {
  return createSelector([getSubcategory], subcategory => {
    if (check instanceof Array) {
      return check.indexOf(subcategory) > -1;
    }

    return subcategory === check;
  });
};
export const getTemplatePath = createContentModelSelector('templatePath'); // LANGUAGE SELECTORS

export const getLanguage = createContentModelSelector('language'); // PUBLISH SELECTORS

export const getPublishDate = createContentModelSelector('publishDate');
export const getOriginalPublishDate = createContentModelSelector('originalPublishDate');
export const getShouldPublishImmediately = createSelector([getContentModel, getContentState], (contentModel, contentState) => {
  const isDraft = StateStatusGroups.DRAFT.includes(contentState);
  const publishImmediately = contentModel.get('publishImmediately');

  if (typeof publishImmediately !== 'boolean' && isDraft) {
    return true;
  }

  return publishImmediately;
}); // FEATURED IMAGE SELECTORS

export const getContentModelFeaturedImage = createContentModelSelector('featuredImage');
export const getContentModelFeaturedImageAltText = createContentModelSelector('featuredImageAltText');
export const getContentModelUseFeaturedImage = createContentModelSelector('useFeaturedImage');
export const getTranslatedFromId = createContentModelSelector('translatedFromId');
export const getPostBody = createContentModelSelector('translatedFromId'); // Dynamic page selectors

export const getDynamicPageDataSourceId = createSelector(getContentModel, contentModel => {
  return contentModel.get('dynamicPageDataSourceId');
});
export const getContentScheduledUpdateDate = createContentModelSelector('scheduledUpdateDate'); // Memberships content access selectors

export const getContentModelPublicAccessRules = createContentModelSelector('publicAccessRules');
export const getContentModelPublicAccessRulesToJS = createSelector([getContentModelPublicAccessRules], immutablePublicAccessRules => immutablePublicAccessRules ? immutablePublicAccessRules.toJS() : []);
export const getIsPublic = createSelector([getContentModelPublicAccessRulesToJS], publicAccessRules => publicAccessRules.some(publicAccessRule => publicAccessRule.type === PublicAccessRuleTypes.PUBLIC));
export const getPublicAccessRulesWallPreviewEnabled = createSelector([getContentModelPublicAccessRulesToJS], publicAccessRules => !!publicAccessRules && publicAccessRules.some(publicAccessRule => publicAccessRule.wallPreviewEnabled));
export const getContentModelPublicAccessRulesEnabled = createContentModelSelector('publicAccessRulesEnabled'); // Mirrors BE BlogPostDataWallWrapperTag.showDataWall conditions
// https://git.hubteam.com/HubSpot/cos-renderer/blob/9c135a0af2c3771d63435f4d7fed4a2fb0652a57/ContentHubL/src/main/java/com/hubspot/content/hubl/tag/BlogPostDataWallWrapperTag.java#L131

export const getShowDataWall = createSelector([getContentModelPublicAccessRulesEnabled, getIsPublic, getPublicAccessRulesWallPreviewEnabled], (publicAccessRulesEnabled, isPublic, publicAccessRulesWallPreviewEnabled) => (publicAccessRulesEnabled || !isPublic) && publicAccessRulesWallPreviewEnabled);