import { useMemo } from 'react';
import { CompletionStatuses } from '../constants';
export const useGenerateAuditResults = ({
  config,
  artifacts
}) => {
  const auditResults = useMemo(() => {
    const allAuditResults = {};
    const {
      groups
    } = config;
    groups.map(group => {
      const {
        recs
      } = group;
      return recs.map(rec => {
        allAuditResults[rec.type] = rec.audit(artifacts);
        return allAuditResults;
      });
    });
    return allAuditResults;
  }, [artifacts, config]);
  return auditResults;
};
export const getGroupIncompleteCount = ({
  auditResults,
  group
}) => {
  const recTypes = group.recs.map(rec => rec.type);
  const incompleteCount = recTypes.filter(recType => {
    const auditResult = auditResults[recType];
    const status = auditResult && auditResult.status;
    return status === CompletionStatuses.INCOMPLETE || status === CompletionStatuses.BLOCKED;
  }).length;
  return incompleteCount;
};