import * as UpgradeProducts from './UpgradeProducts';
import * as links from '../core/utilities/links';
import * as ApiNames from './ApiNames';
const {
  SALES_PRO,
  SALES_FREE,
  SALES_STARTER,
  SALES_PROFESSIONAL,
  SALES_ENTERPRISE,
  SALES_STARTER_USER,
  SALES_PROFESSIONAL_USER,
  SALES_ENTERPRISE_USER,
  MARKETING_FREE,
  MARKETING_STARTER,
  MARKETING_STARTER_EMAIL,
  MARKETING_BASIC,
  MARKETING_PRO,
  MARKETING_ENTERPRISE,
  SERVICE_FREE,
  SERVICE_STARTER,
  SERVICE_PROFESSIONAL,
  SERVICE_ENTERPRISE,
  SERVICE_STARTER_USER,
  SERVICE_PROFESSIONAL_USER,
  SERVICE_ENTERPRISE_USER,
  STARTER_CONTACTS,
  BASIC_CONTACTS,
  MARKETING_STARTER_CONTACTS,
  MARKETING_PROFESSIONAL_CONTACTS,
  MARKETING_ENTERPRISE_CONTACTS,
  ADS,
  ADS_CAPACITY,
  WEBSITE,
  REPORTING,
  DEDICATED_IP,
  TRANSACTIONAL_EMAIL,
  ADDITIONAL_ACCOUNT,
  BRAND_DOMAIN,
  WORKFLOWS_ADDON,
  LISTS_ADDON,
  ESIGNATURES_LIMIT_INCREASE,
  ESIGNATURE_LIMIT_INCREASE,
  DOMAINS_LIMIT_INCREASE,
  DESIGNATED_TECHNICAL_SUPPORT,
  IN_PERSON_TRAINING,
  STARTER_KIT,
  SALES_PROFESSIONAL_ONBOARDING,
  SERVICE_PROFESSIONAL_ONBOARDING,
  INBOUND_CONSULTING_BLOCK,
  TECHNICAL_CONSULTING_BLOCK,
  GENERAL,
  CRM,
  CMS_STARTER,
  CMS_PROFESSIONAL,
  CMS_ENTERPRISE,
  OPERATIONS_STARTER,
  OPERATIONS_PROFESSIONAL,
  OPERATIONS_ENTERPRISE,
  ENTERPRISE,
  PARTNER_PROGRAM,
  SUITE_STARTER,
  SUITE_PROFESSIONAL,
  SUITE_ENTERPRISE,
  BUNDLE,
  MARKETING,
  SALES,
  SERVICE,
  CUSTOM_SSL,
  SANDBOX_LIMIT_INCREASE,
  CALLING_LIMIT_INCREASE,
  PHONE_LIMIT_INCREASE,
  TEAMS_LIMIT_INCREASE,
  WHATSAPP_LIMIT_INCREASE,
  VIDEO_LIMIT_INCREASE,
  MARKETING_SMS_ADDON,
  MARKETING_SMS_LIMIT_INCREASE,
  CORE_SEATS_STARTER,
  CORE_SEATS_PROFESSIONAL,
  CORE_SEATS_ENTERPRISE
} = UpgradeProducts;
const UpgradeProductProperties = {
  [GENERAL]: {
    upgradeLink: links.productsAndAddons
  },
  [SALES_PRO]: {
    upgradeLink: links.salesPricing
  },
  [SALES_FREE]: {
    upgradeLink: links.salesPricing
  },
  [SALES_STARTER]: {
    upgradeLink: links.salesPricing
  },
  [SALES_PROFESSIONAL]: {
    upgradeLink: links.salesPricing
  },
  [SALES_ENTERPRISE]: {
    upgradeLink: links.salesPricing
  },
  [SALES_STARTER_USER]: {
    upgradeLink: links.salesPricing
  },
  [SALES_PROFESSIONAL_USER]: {
    upgradeLink: links.salesPricing
  },
  [SALES_ENTERPRISE_USER]: {
    upgradeLink: links.salesPricing
  },
  [MARKETING_FREE]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_STARTER]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_STARTER_EMAIL]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_BASIC]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_PRO]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_ENTERPRISE]: {
    upgradeLink: links.marketingPricing
  },
  [SERVICE_FREE]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_STARTER]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_PROFESSIONAL]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_ENTERPRISE]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_STARTER_USER]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_PROFESSIONAL_USER]: {
    upgradeLink: links.servicePricing
  },
  [SERVICE_ENTERPRISE_USER]: {
    upgradeLink: links.servicePricing
  },
  [BASIC_CONTACTS]: {
    upgradeLink: links.marketingPricing
  },
  [STARTER_CONTACTS]: {
    upgradeLink: links.starterContacts
  },
  [MARKETING_STARTER_CONTACTS]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_PROFESSIONAL_CONTACTS]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_ENTERPRISE_CONTACTS]: {
    upgradeLink: links.marketingPricing
  },
  [CMS_STARTER]: {
    upgradeLink: links.cmsPricing
  },
  [CMS_PROFESSIONAL]: {
    upgradeLink: links.cmsPricing
  },
  [CMS_ENTERPRISE]: {
    upgradeLink: links.cmsPricing
  },
  [OPERATIONS_STARTER]: {
    upgradeLink: links.operationsPricing
  },
  [OPERATIONS_PROFESSIONAL]: {
    upgradeLink: links.operationsPricing
  },
  [OPERATIONS_ENTERPRISE]: {
    upgradeLink: links.operationsPricing
  },
  [ADS]: {
    upgradeLink: links.addonAds
  },
  [ADS_CAPACITY]: {
    upgradeLink: links.addonAds
  },
  [WEBSITE]: {
    upgradeLink: links.addonWebsite
  },
  [REPORTING]: {
    upgradeLink: links.addonReporting
  },
  [DEDICATED_IP]: {
    upgradeLink: links.addonDedicatedIp
  },
  [TRANSACTIONAL_EMAIL]: {
    upgradeLink: links.addonTransactionalEmail
  },
  [ADDITIONAL_ACCOUNT]: {
    upgradeLink: links.addonAdditionalPortalLink
  },
  [BRAND_DOMAIN]: {
    upgradeLink: links.addonBrandDomain
  },
  [WORKFLOWS_ADDON]: {
    upgradeLink: links.marketingPricing
  },
  [MARKETING_SMS_ADDON]: {
    upgradeLink: links.addonMarketingSms
  },
  [LISTS_ADDON]: {
    upgradeLink: links.marketingPricing
  },
  [ESIGNATURES_LIMIT_INCREASE]: {
    upgradeLink: links.salesPricing
  },
  [ESIGNATURE_LIMIT_INCREASE]: {
    upgradeLink: links.salesPricing
  },
  [DOMAINS_LIMIT_INCREASE]: {
    upgradeLink: links.marketingPricing
  },
  [CUSTOM_SSL]: {
    upgradeLink: links.addonCustomSsl
  },
  [SANDBOX_LIMIT_INCREASE]: {
    upgradeLink: links.addonSandbox
  },
  [DESIGNATED_TECHNICAL_SUPPORT]: {
    upgradeLink: links.serviceDesignatedTechnicalSupport
  },
  [IN_PERSON_TRAINING]: {
    upgradeLink: links.serviceInPersonTraining
  },
  [STARTER_KIT]: {
    upgradeLink: links.serviceStarterKit
  },
  [SALES_PROFESSIONAL_ONBOARDING]: {
    upgradeLink: links.salesProfessionalOnboarding
  },
  [SERVICE_PROFESSIONAL_ONBOARDING]: {
    upgradeLink: links.serviceProfessionalOnboarding
  },
  [INBOUND_CONSULTING_BLOCK]: {
    upgradeLink: links.inboundConsultingBlock
  },
  [TECHNICAL_CONSULTING_BLOCK]: {
    upgradeLink: links.technicalConsultingBlock
  },
  [ENTERPRISE]: {
    upgradeLink: links.marketingPricing
  },
  [CRM]: {
    upgradeLink: links.addonCrm
  },
  [PARTNER_PROGRAM]: {
    upgradeLink: links.partnerProgramPaywall
  },
  [SUITE_STARTER]: {
    upgradeLink: links.suite
  },
  [SUITE_PROFESSIONAL]: {
    upgradeLink: links.suite
  },
  [SUITE_ENTERPRISE]: {
    upgradeLink: links.suite
  },
  [BUNDLE]: {
    upgradeLink: links.bundle
  },
  [MARKETING]: {
    upgradeLink: links.marketingPricing
  },
  [SALES]: {
    upgradeLink: links.salesPricing
  },
  [SERVICE]: {
    upgradeLink: links.servicePricing
  },
  [CALLING_LIMIT_INCREASE]: {
    upgradeLink: links.addonCalling
  },
  [PHONE_LIMIT_INCREASE]: {
    upgradeLink: links.addonPhone
  },
  [TEAMS_LIMIT_INCREASE]: {
    upgradeLink: links.addonTeams
  },
  [WHATSAPP_LIMIT_INCREASE]: {
    upgradeLink: links.addonWhatsapp
  },
  [MARKETING_SMS_LIMIT_INCREASE]: {
    upgradeLink: links.limitIncreaseMarketingSms
  },
  [VIDEO_LIMIT_INCREASE]: {
    upgradeLink: links.addonVideo
  },
  [CORE_SEATS_STARTER]: {
    upgradeLink: links.bundle
  },
  [CORE_SEATS_PROFESSIONAL]: {
    upgradeLink: links.bundle
  },
  [CORE_SEATS_ENTERPRISE]: {
    upgradeLink: links.bundle
  }
};
export const UpgradeProductPropertiesFromApiName = {
  // commented out below did not have equivalent API name mappings
  // these had no Pricing Pages PageView amplitude events for the associated URL
  // [DESIGNATED_TECHNICAL_SUPPORT]: {
  //   upgradeLink: links.serviceDesignatedTechnicalSupport,
  // },
  // [WEBSITE]: { upgradeLink: links.addonWebsite },
  // [ADDITIONAL_ACCOUNT]: { upgradeLink: links.addonAdditionalPortalLink },
  // [STARTER_KIT]: { upgradeLink: links.serviceStarterKit },
  // [IN_PERSON_TRAINING]: { upgradeLink: links.serviceInPersonTraining },
  // can't determine if viewed
  // [BASIC_CONTACTS]: { upgradeLink: links.marketingPricing },
  // [STARTER_CONTACTS]: { upgradeLink: links.starterContacts },
  // [MARKETING_STARTER_CONTACTS]: { upgradeLink: links.marketingPricing },
  // [MARKETING_PROFESSIONAL_CONTACTS]: { upgradeLink: links.marketingPricing },
  // [MARKETING_ENTERPRISE_CONTACTS]: { upgradeLink: links.marketingPricing },
  // [ADS_CAPACITY]: { upgradeLink: links.addonAds },
  // [MARKETING]: { upgradeLink: links.marketingPricing },
  // [SALES]: { upgradeLink: links.salesPricing },
  // [SERVICE]: { upgradeLink: links.servicePricing },
  [GENERAL]: {
    upgradeLink: links.productsAndAddons
  },
  [BUNDLE]: {
    upgradeLink: links.bundle
  },
  [ApiNames.SALES_HUB_FREE]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.SALES_HUB_STARTER]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.SALES_HUB_PROFESSIONAL]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.SALES_HUB_ENTERPRISE]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.MARKETING_HUB_FREE]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.MARKETING_HUB_STARTER]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.MARKETING_HUB_BASIC]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.MARKETING_HUB_PROFESSIONAL]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.MARKETING_HUB_ENTERPRISE]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.SERVICE_HUB_FREE]: {
    upgradeLink: links.servicePricing
  },
  [ApiNames.SERVICE_HUB_STARTER]: {
    upgradeLink: links.servicePricing
  },
  [ApiNames.SERVICE_HUB_PROFESSIONAL]: {
    upgradeLink: links.servicePricing
  },
  [ApiNames.SERVICE_HUB_ENTERPRISE]: {
    upgradeLink: links.servicePricing
  },
  [ApiNames.CMS_HUB_STARTER]: {
    upgradeLink: links.cmsPricing
  },
  [ApiNames.CMS_HUB_PROFESSIONAL]: {
    upgradeLink: links.cmsPricing
  },
  [ApiNames.CMS_HUB_ENTERPRISE]: {
    upgradeLink: links.cmsPricing
  },
  [ApiNames.OPERATIONS_HUB_STARTER]: {
    upgradeLink: links.operationsPricing
  },
  [ApiNames.OPERATIONS_HUB_PROFESSIONAL]: {
    upgradeLink: links.operationsPricing
  },
  [ApiNames.OPERATIONS_HUB_ENTERPRISE]: {
    upgradeLink: links.operationsPricing
  },
  [ApiNames.ADS_LIMIT_INCREASE]: {
    upgradeLink: links.addonAds
  },
  [ApiNames.REPORTING_LIMIT_INCREASE]: {
    upgradeLink: links.addonReporting
  },
  [ApiNames.DEDICATED_IP]: {
    upgradeLink: links.addonDedicatedIp
  },
  [ApiNames.TRANSACTIONAL_EMAIL]: {
    upgradeLink: links.addonTransactionalEmail
  },
  [ApiNames.BRAND_DOMAIN]: {
    upgradeLink: links.addonBrandDomain
  },
  [ApiNames.WORKFLOWS_LIMIT_INCREASE]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.LISTS_LIMIT_INCREASE]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.ESIGNATURES_LIMIT_INCREASE]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.ESIGNATURE_LIMIT_INCREASE]: {
    upgradeLink: links.salesPricing
  },
  [ApiNames.DOMAINS_LIMIT_INCREASE]: {
    upgradeLink: links.marketingPricing
  },
  [ApiNames.CUSTOM_SSL]: {
    upgradeLink: links.addonCustomSsl
  },
  [ApiNames.SANDBOX_LIMIT_INCREASE]: {
    upgradeLink: links.addonSandbox
  },
  [ApiNames.STARTER_KIT]: {
    upgradeLink: links.serviceStarterKit
  },
  [ApiNames.SALES_HUB_PROFESSIONAL_ONBOARDING]: {
    upgradeLink: links.salesProfessionalOnboarding
  },
  [ApiNames.SERVICE_HUB_PROFESSIONAL_ONBOARDING]: {
    upgradeLink: links.serviceProfessionalOnboarding
  },
  [ApiNames.INBOUND_CONSULTING_ADVANCED]: {
    upgradeLink: links.inboundConsultingBlock
  },
  [ApiNames.INBOUND_CONSULTING_PREMIUM]: {
    upgradeLink: links.inboundConsultingBlock
  },
  [ApiNames.TECHNICAL_CONSULTING_ONGOING]: {
    upgradeLink: links.technicalConsultingBlock
  },
  [ApiNames.TECHNICAL_CONSULTING_4_HOUR]: {
    upgradeLink: links.technicalConsultingBlock
  },
  [ApiNames.TECHNICAL_CONSULTING_ADVANCED]: {
    upgradeLink: links.technicalConsultingBlock
  },
  [ApiNames.TECHNICAL_CONSULTING_PREMIUM]: {
    upgradeLink: links.technicalConsultingBlock
  },
  [ApiNames.CRM_SUITE_STARTER_BUNDLE]: {
    upgradeLink: links.suite
  },
  [ApiNames.CRM_SUITE_PROFESSIONAL_BUNDLE]: {
    upgradeLink: links.suite
  },
  [ApiNames.CRM_SUITE_ENTERPRISE_BUNDLE]: {
    upgradeLink: links.suite
  },
  [ApiNames.CALLING_LIMIT_INCREASE]: {
    upgradeLink: links.addonCalling
  },
  [ApiNames.PHONE_LIMIT_INCREASE]: {
    upgradeLink: links.addonPhone
  },
  [ApiNames.TEAMS_LIMIT_INCREASE]: {
    upgradeLink: links.addonTeams
  },
  [ApiNames.WHATSAPP_LIMIT_INCREASE]: {
    upgradeLink: links.addonWhatsapp
  },
  [MARKETING_SMS_ADDON]: {
    upgradeLink: links.addonMarketingSms
  },
  [MARKETING_SMS_LIMIT_INCREASE]: {
    upgradeLink: links.limitIncreaseMarketingSms
  },
  [ApiNames.VIDEO_LIMIT_INCREASE]: {
    upgradeLink: links.addonVideo
  },
  [ApiNames.CORE_SEATS_STARTER]: {
    upgradeLink: links.bundle
  },
  [ApiNames.CORE_SEATS_PROFESSIONAL]: {
    upgradeLink: links.bundle
  },
  [ApiNames.CORE_SEATS_ENTERPRISE]: {
    upgradeLink: links.bundle
  }
};
export default UpgradeProductProperties;