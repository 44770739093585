export const SeoRecommendationGroups = {
  CONTENT: 'CONTENT',
  CRAWLING_AND_INDEXING: 'CRAWLING_AND_INDEXING',
  HEADER: 'HEADER',
  BLOG_HEADER: 'BLOG_HEADER',
  IMAGES: 'IMAGES',
  LINKS: 'LINKS',
  META_DESCRIPTION: 'META_DESCRIPTION',
  MOBILE_FRIENDLINESS: 'MOBILE_FRIENDLINESS',
  TITLE: 'TITLE',
  TOPICS: 'TOPICS'
};
export const SeoRecommendations = {
  ADD_H1_TAGS: 'ADD_H1_TAGS',
  FIX_H1_TAGS_PAGES: 'FIX_H1_TAGS_PAGES',
  FIX_H1_TAGS_BLOG: 'FIX_H1_TAGS_BLOG',
  ADD_HYPERLINK_TO_PILLAR_PAGE: 'ADD_HYPERLINK_TO_PILLAR_PAGE',
  ADD_IMAGE_ALT_TEXT: 'ADD_IMAGE_ALT_TEXT',
  ADD_META_DESCRIPTION: 'ADD_META_DESCRIPTION',
  ADD_TITLE: 'ADD_TITLE',
  ADD_TOPIC_PHRASE_TO_META_DESCRIPTION: 'ADD_TOPIC_PHRASE_TO_META_DESCRIPTION',
  ADD_TOPIC_PHRASE_TO_TITLE: 'ADD_TOPIC_PHRASE_TO_TITLE',
  ADD_VIEWPORT_TAG: 'ADD_VIEWPORT_TAG',
  DECREASE_OUTBOUND_LINK_COUNT: 'DECREASE_OUTBOUND_LINK_COUNT',
  INCREASE_CLUSTER_PHRASE_IN_BODY_COUNT: 'INCREASE_CLUSTER_PHRASE_IN_BODY_COUNT',
  INCREASE_WORD_COUNT: 'INCREASE_WORD_COUNT',
  MAKE_PAGE_INDEXABLE_BY_SEARCH_ENGINES: 'MAKE_PAGE_INDEXABLE_BY_SEARCH_ENGINES',
  REMOVE_DOMAIN_FROM_TITLE: 'REMOVE_DOMAIN_FROM_TITLE',
  REMOVE_TITLE_FROM_META_DESCRIPTION: 'REMOVE_TITLE_FROM_META_DESCRIPTION',
  SHORTEN_META_DESCRIPTION: 'SHORTEN_META_DESCRIPTION',
  SHORTEN_TITLE: 'SHORTEN_TITLE',
  VERIFY_EMPTY_IMAGE_ALT_TEXT: 'VERIFY_EMPTY_IMAGE_ALT_TEXT'
};