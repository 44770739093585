import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAssetCommentsLastReadTimestampKey } from '../constants/userAttributes';
import { isFromCurrentUser } from '../utils/commentingUtils';
import useUserSettings from './useUserSettings';
import { UNREAD_COMMENTS } from '../constants/gates';
import { isUngated } from '../utils/auth';
import { findLast } from '../utils/helpers';
import useFetchComments from './useFetchComments';
import useCollaborationSidebarState from './useCollaborationSidebarState';
import useIsCommentsDataStale from './useIsCommentsDataStale';
import useTrackLiveRefresh from './useTrackLiveRefresh'; // As we can't distinguish between read/unread prior to rolling out, assuming everything read
// prior to this date to reduce false positives.

const LAST_READ_TIMESTAMP_UNGATED_BY_DATE = new Date(2022, 9, 12);
export default function useHasUnreadComments(objectType, objectId) {
  const {
    fetchComments,
    comments,
    error,
    loading,
    updateCache: updateCommentsCache
  } = useFetchComments();
  const {
    assetsLastUpdated
  } = useCollaborationSidebarState();
  const isCommentsDataStale = useIsCommentsDataStale({
    objectType,
    objectId,
    assetsLastUpdated
  });
  const isUngatedToUnreadComments = isUngated(UNREAD_COMMENTS); // TODO: remove this after the MO:CollaborationSidebar:UnreadComments is ungated at 100R%

  const [mostRecentCommentFromDifferentUserTimestamp, setMostRecentCommentFromDifferentUserTimestamp] = useState(null);
  const {
    getUserSetting,
    setUserSetting,
    hasLoaded: userSettingsLoaded
  } = useUserSettings();
  const userAttributesKey = objectId ? getAssetCommentsLastReadTimestampKey(objectType, objectId) : null;
  useEffect(() => {
    fetchComments({
      objectType,
      objectId,
      refetch: isCommentsDataStale
    });
  }, [fetchComments, objectId, objectType, isCommentsDataStale]);
  useEffect(() => {
    if (loading || error) {
      return;
    }

    const mostRecentCommentFromDifferentUser = findLast(comments, c => !isFromCurrentUser(c));

    if (mostRecentCommentFromDifferentUser) {
      setMostRecentCommentFromDifferentUserTimestamp(Date.parse(mostRecentCommentFromDifferentUser.createdAt));
    }
  }, [comments, loading, error]);
  const lastReadTimestamp = useMemo(() => {
    if (!userSettingsLoaded || !userAttributesKey) {
      return null;
    }

    const timestampStr = getUserSetting(userAttributesKey);
    return timestampStr ? parseInt(timestampStr, 10) : null;
  }, [userSettingsLoaded, getUserSetting, userAttributesKey]); // TODO: remove this after the MO:CollaborationSidebar:UnreadComments is ungated at 100R%

  const onCommentsRead = useCallback(timestamp => {
    if (userAttributesKey) {
      setUserSetting(userAttributesKey, timestamp);
    }
  }, [userAttributesKey, setUserSetting]);
  const timestampToCheckForUnread = lastReadTimestamp || LAST_READ_TIMESTAMP_UNGATED_BY_DATE;
  const unreadCommentsCount = useMemo(() => {
    const unreadComments = comments.filter(comment => !comment.read);
    return unreadComments.length;
  }, [comments]); // TODO: replace hasUnreadComments with the unreadCommentsCount after the MO:CollaborationSidebar:UnreadComments is ungated at 100%

  const hasUnreadComments = useMemo(() => {
    if (isUngatedToUnreadComments) {
      return unreadCommentsCount > 0;
    }

    if (!userSettingsLoaded || !mostRecentCommentFromDifferentUserTimestamp) {
      return false;
    }

    return timestampToCheckForUnread < mostRecentCommentFromDifferentUserTimestamp;
  }, [userSettingsLoaded, timestampToCheckForUnread, mostRecentCommentFromDifferentUserTimestamp, isUngatedToUnreadComments, unreadCommentsCount]);
  const markCommentAsRead = useCallback(updatedCommentId => {
    if (!objectId) {
      return;
    }

    const updatedComments = comments.map(comment => {
      if (comment.id === updatedCommentId) {
        return Object.assign({}, comment, {
          read: true
        });
      }

      return comment;
    });
    updateCommentsCache({
      objectType,
      objectId,
      updatedComments
    });
  }, [comments, objectId, objectType, updateCommentsCache]);
  useTrackLiveRefresh({
    unreadCommentsCount,
    unreadCommentsLoading: loading
  });
  return {
    hasUnreadComments,
    unreadCommentsCount,
    onCommentsRead,
    lastReadTimestamp,
    lastReadTimestampLoading: !userSettingsLoaded,
    refetchComments: () => fetchComments({
      objectType,
      objectId,
      refetch: true
    }),
    markCommentAsRead
  };
}