import { CompletionStatuses } from '../../../../../constants';
export const addTopicPhraseToTitleAudit = (pageTitle, topicPhrase) => {
  if (!topicPhrase) {
    return {
      status: CompletionStatuses.NOT_APPLICABLE
    };
  }

  if (!pageTitle) {
    return {
      status: CompletionStatuses.BLOCKED,
      topicPhrase
    };
  }

  let status;
  const pageTitleLowerCase = pageTitle.toLowerCase();
  const topicPhraseLowerCase = topicPhrase.toLowerCase();

  if (pageTitleLowerCase.includes(topicPhraseLowerCase)) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }

  return {
    status,
    topicPhrase
  };
};