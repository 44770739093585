'use es6';

import { createSelector } from 'reselect';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getHasCrmScope, getHasWorkflowsDealsAccessScope, getHasWorkflowsTicketsAccessScope, getHasWorkflowsQuotesAccessScope, getIsUngatedForCartPersonalizationToken, getIsUngatedForCartSync, getIsSuperAdmin } from './authSelectors';
import { COMPANY_FQN, CONTACT_FQN, DEAL_FQN, QUOTE_FQN, TICKET_FQN, SUBSCRIPTION_FQN, COMMERCE_PAYMENT_FQN, INVOICE_FQN, CART_FQN } from 'rich-text-lib/constants/properties';
import { getPaymentsEligibility } from 'ContentEditorUI/redux/selectors/paymentsEligibilitySelectors';
import { FEATURE_FLAGS } from '../constants';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import { getIsContentAssistanceEnabled } from 'ContentEditorUI/redux/selectors/contentAssistantSelectors';
export const getCanInsertDealTokens = createSelector([getSubcategory, getHasWorkflowsDealsAccessScope], (subcategory, scoped) => {
  return subcategory === 'automated_for_deal' || ['automated', 'automated_ab_master', 'automated_ab_variant'].includes(subcategory) && scoped;
});
export const getCanInsertTicketTokens = createSelector([getSubcategory, getHasWorkflowsTicketsAccessScope], (subcategory, scoped) => {
  return ['automated', 'automated_ab_master', 'automated_ab_variant', 'ticket_opened_kickback_email', 'ticket_closed_kickback_email', 'automated_for_ticket'].includes(subcategory) && scoped;
});
export const getCanInsertQuoteTokens = createSelector([getSubcategory, getHasWorkflowsQuotesAccessScope], (subcategory, scoped) => {
  return subcategory.indexOf('automated') > -1 && scoped;
});
export const getCanInsertSubscriptionTokens = createSelector([getSubcategory], subcategory => subcategory.indexOf('automated') > -1);
export const getCanInsertInvoiceTokens = createSelector([getSubcategory], subcategory => subcategory.indexOf('automated') > -1);
export const getCanInsertCartTokens = createSelector([getSubcategory, getIsUngatedForCartSync, getIsUngatedForCartPersonalizationToken], (subcategory, ungatedForCartSync, ungatedForCartPersonalizationToken) => {
  return subcategory.indexOf('automated') > -1 && ungatedForCartSync && ungatedForCartPersonalizationToken;
});
export const getCanInsertPaymentsToken = createSelector([getSubcategory, getPaymentsEligibility], (subcategory, paymentsEligibility) => {
  return paymentsEligibility && paymentsEligibility.get('chargesEnabled') && subcategory.indexOf('automated') > -1;
});
export const getCanInsertInternalContactRecord = createSelector(getSubcategory, subcategory => subcategory.indexOf('automated') > -1);

const getIsFormFollowupEmail = (state, props) => {
  return props && props.isFormFollowupEmail;
};

export const getSupportedCrmObjectFQNs = createSelector([getHasCrmScope, getCanInsertDealTokens, getCanInsertTicketTokens, getCanInsertQuoteTokens, getCanInsertSubscriptionTokens, getCanInsertPaymentsToken, getCanInsertInvoiceTokens, getCanInsertCartTokens, getIsFormFollowupEmail], (canInsertCompanyTokens, canInsertDealTokens, canInsertTicketTokens, canInsertQuoteTokens, canInsertSubscriptionTokens, canInsertPaymentsToken, canInsertInvoiceTokens, canInsertCartTokens, isFormFollowupEmail) => {
  if (isFormFollowupEmail) return [CONTACT_FQN];
  return [CONTACT_FQN, canInsertCompanyTokens && COMPANY_FQN, canInsertDealTokens && DEAL_FQN, canInsertTicketTokens && TICKET_FQN, canInsertQuoteTokens && QUOTE_FQN, canInsertSubscriptionTokens && SUBSCRIPTION_FQN, canInsertPaymentsToken && COMMERCE_PAYMENT_FQN, canInsertInvoiceTokens && INVOICE_FQN, canInsertCartTokens && CART_FQN].filter(Boolean);
});
export const getHasAIGeneratedContentFeature = createSelector(() => {
  const aiGeneratedContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.aiGeneratedContent, true);
  return aiGeneratedContentFlag;
});
export const getCanUseAIGeneratedContent = createSelector([getIsContentAssistanceEnabled], isContentAssistanceEnabled => {
  const aiGeneratedContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.aiGeneratedContent, true);
  return isContentAssistanceEnabled && aiGeneratedContentFlag;
});
export const getCanUseContentAssistantOptInIntroModal = createSelector([getIsContentAssistanceEnabled, getIsSuperAdmin], (isContentAssistanceEnabled, isSuperAdmin) => {
  const aiGeneratedContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.aiGeneratedContent, true);
  return (// Edge case: we need to show the intro modal for super admins so they can decide whether to use or not.
    (isContentAssistanceEnabled || isSuperAdmin) && aiGeneratedContentFlag
  );
});