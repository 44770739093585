import * as helpers from './common/helpers';
export const createEventPool = () => {
  const eventQueue = helpers.createQueue();
  return {
    peek: eventQueue.peek,
    push: event => eventQueue.enqueue(event),
    flush: () => {
      const events = [];

      do {
        const event = eventQueue.dequeue();

        if (event) {
          events.unshift(event);
        }
      } while (eventQueue.peek());

      return events;
    }
  };
};