import { useMemo } from 'react';
import { ApprovalStatus, ApprovalStepStatus, ApprovalUserType } from '../types';
import { useApprovalContext } from './useApprovalContext';
export const useApprovalConditions = () => {
  const approval = useApprovalContext();
  return useMemo(() => {
    const {
      config,
      meta,
      data
    } = approval;
    const {
      approvalStatus,
      isLoading,
      isSuperAdmin,
      currentUserType,
      stepStatusForCurrentUser,
      userCanSkipThisApproval,
      restartOnConclude
    } = meta;
    const noApprovalData = data === null; // config.enabled can be null when config fetch has not finished. So these are just explicit checks

    const isEnabled = config.enabled === true;
    const isDisabled = config.enabled === false;
    const isNotStarted = noApprovalData || approvalStatus === ApprovalStatus.CANCELLED;
    const isConcluded = data && data.approvalConcluded;
    const isApproved = approvalStatus === ApprovalStatus.APPROVED;
    const isConcludedAndShouldRestartOnConclude = restartOnConclude && isConcluded;
    const isUserStepPending = stepStatusForCurrentUser === ApprovalStepStatus.APPROVAL_PENDING;
    const isUserStepApproved = stepStatusForCurrentUser === ApprovalStepStatus.APPROVED; // This is used as a source of truth of whether approvals is allowing render
    // of high risk action button for any reason (enabled, skip or restarting approvals).

    const canUserTakeApprovableAction = !isEnabled || userCanSkipThisApproval || isApproved && !isConcludedAndShouldRestartOnConclude;
    const canEditApproval = approvalStatus === ApprovalStatus.APPROVAL_PENDING && (currentUserType === ApprovalUserType.REQUESTER || isSuperAdmin); // Once we know enabled is false, we consider the fetch flow complete
    // We consider waiting for approvable object id as part of the fetch

    const isFetchComplete = config.enabled === false || !!meta.approvableObjectId && config.enabled !== null && !meta.isLoading;
    return {
      canEditApproval,
      canUserTakeApprovableAction,
      isApproved,
      isApprover: currentUserType === ApprovalUserType.APPROVER,
      isCancelled: approvalStatus === ApprovalStatus.CANCELLED,
      isConcluded,
      isConcludedAndShouldRestartOnConclude,
      isDisabled,
      isEnabled,
      isFetchComplete,
      isLoading,
      isNonParticipant: currentUserType === ApprovalUserType.NON_PARTICIPANT,
      isNotStarted,
      isPending: approvalStatus === ApprovalStatus.APPROVAL_PENDING,
      isRequester: currentUserType === ApprovalUserType.REQUESTER,
      isSuperAdmin,
      isUserStepApproved,
      isUserStepPending,
      noApprovalData,
      userCanSkipThisApproval
    };
  }, [approval]);
};
export default useApprovalConditions;