import { getPageTitle } from './pageTitle';
import { getLinks } from './links';
import { getH1Elements } from './h1Elements';
import { getImages } from './images';
import { getMetaTags } from './metaTags';
import { getPostBody } from './postBody';
import { getDOMBody } from './DOMBody';
import { getApexDomain } from './apexDomain';
import { getAllModules } from './allModules';
export const GathererKeys = {
  ALL_MODULES: 'ALL_MODULES',
  APEX_DOMAIN: 'APEX_DOMAIN',
  DOM_BODY: 'DOM_BODY',
  H1_ELEMENTS: 'H1_ELEMENTS',
  IMAGES: 'IMAGES',
  LINKS: 'LINKS',
  META_TAGS: 'META_TAGS',
  PAGE_TITLE: 'PAGE_TITLE',
  POST_BODY: 'POST_BODY'
};
export const GathererExecutionLocations = {
  INPAGE_EDITOR: 'INPAGE_EDITOR',
  PARENT_EDITOR: 'PARENT_EDITOR'
};
export const GathererConfig = {
  [GathererKeys.ALL_MODULES]: {
    key: GathererKeys.ALL_MODULES,
    location: GathererExecutionLocations.PARENT_EDITOR,
    execute: getAllModules
  },
  [GathererKeys.APEX_DOMAIN]: {
    key: GathererKeys.APEX_DOMAIN,
    location: GathererExecutionLocations.PARENT_EDITOR,
    execute: getApexDomain
  },
  [GathererKeys.DOM_BODY]: {
    key: GathererKeys.DOM_BODY,
    location: GathererExecutionLocations.INPAGE_EDITOR,
    execute: getDOMBody
  },
  [GathererKeys.H1_ELEMENTS]: {
    key: GathererKeys.H1_ELEMENTS,
    location: GathererExecutionLocations.INPAGE_EDITOR,
    execute: getH1Elements
  },
  [GathererKeys.IMAGES]: {
    key: GathererKeys.IMAGES,
    location: GathererExecutionLocations.INPAGE_EDITOR,
    execute: getImages
  },
  [GathererKeys.LINKS]: {
    key: GathererKeys.LINKS,
    location: GathererExecutionLocations.INPAGE_EDITOR,
    execute: getLinks
  },
  [GathererKeys.META_TAGS]: {
    key: GathererKeys.META_TAGS,
    location: GathererExecutionLocations.INPAGE_EDITOR,
    execute: getMetaTags
  },
  [GathererKeys.PAGE_TITLE]: {
    key: GathererKeys.PAGE_TITLE,
    location: GathererExecutionLocations.PARENT_EDITOR,
    execute: getPageTitle
  },
  [GathererKeys.POST_BODY]: {
    key: GathererKeys.POST_BODY,
    location: GathererExecutionLocations.PARENT_EDITOR,
    execute: getPostBody
  }
};