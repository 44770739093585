export const IMAGE_GENERATION_BATCH_SIZE = 4;
export const IMAGE_GENERATION_MAX_PROMPT_LENGTH = 400;
export const IMAGE_GENERATION_COMMAND = 'blog_slash-command_generate-image_v1';
export const IMAGE_VARIATIONS_COMMAND = 'image_variation_v1';
export const IMAGE_GENERATION_POLLING_INTERVAL = 1000;
export const IMAGE_GENERATION_POLLING_MAX_ATTEMPTS = 30;
export const GENERATED_IMAGE_FOLDER_PATH = 'Generated images';
export const GeneratedImageDimensions = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};
export const GeneratedImageDimensionResolutions = {
  [GeneratedImageDimensions.SMALL]: 256,
  [GeneratedImageDimensions.MEDIUM]: 512,
  [GeneratedImageDimensions.LARGE]: 1024
};
export const ImageDimensions = {
  width: GeneratedImageDimensionResolutions[GeneratedImageDimensions.LARGE],
  height: GeneratedImageDimensionResolutions[GeneratedImageDimensions.LARGE]
}; // values are purposefully english as we append them in english as `in {{ value }} style`

export const STYLE_OPTIONS = ['abstract', 'vector graphics', 'pop art', 'minimalist', 'digital art', '3D', 'watercolor', 'oil painting', 'ink print', 'black and white sketch', 'retrowave', 'cinematic'];