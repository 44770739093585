import http from 'hub-http/clients/apiClient';
const BASE_URL = 'users/v1/app/attributes';
export function fetchUserAttributes(keys) {
  return http.get(BASE_URL, {
    query: {
      keys
    }
  });
}
export function setUserAttribute(attribute, value) {
  return http.post(BASE_URL, {
    data: {
      key: attribute,
      value
    }
  });
}