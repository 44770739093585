import produce from 'immer';
import { SELECT_BREAKPOINT, FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  activeBreakpointName: 'default',
  activeBreakpointPreviewWidth: null,
  breakpoints: [{
    name: 'default'
  }]
};
export const styleBreakpointsReducer = (state = initialState, action) => produce(state, draft => {
  const {
    type,
    response,
    payload
  } = action;

  switch (type) {
    case SELECT_BREAKPOINT:
      if (payload.previewWidth) {
        const {
          units,
          value
        } = payload.previewWidth;
        const previewWidth = `${value}${units}`;
        draft.activeBreakpointName = payload.selectedBreakpoint;
        draft.activeBreakpointPreviewWidth = previewWidth;
        return draft;
      }

      draft.activeBreakpointName = payload.selectedBreakpoint;
      draft.activeBreakpointPreviewWidth = null;
      return draft;

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          breakpoint_config
        } = response;

        if (breakpoint_config) {
          const breakpoints = breakpoint_config.breakpoints;
          const newBreakpoints = [];
          breakpoints.forEach((breakpoint, index) => {
            breakpoint.order = index;
            newBreakpoints.push(breakpoint);
          });
          draft.breakpoints = newBreakpoints;
        }

        return draft;
      }

    default:
      return draft;
  }
});
export default styleBreakpointsReducer;