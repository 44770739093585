import { useCallback, useReducer } from 'react';
import { MSG_TYPE_OPEN_CAMPAIGN_CREATE_PANEL, MSG_TYPE_CONFIRM_CAMPAIGN_CREATE, MSG_TYPE_CREATE_CAMPAIGN_SUCCESS, MSG_TYPE_CREATE_CAMPAIGN_FAILURE } from 'collaboration-sidebar/constants/IFrameMessageTypes';
const INITIAL_STATE = {
  failed: false,
  loading: false,
  show: false
};
const HANDLE_CONFIRM = 'HANDLE_CONFIRM';
const HANDLE_CLOSE = 'HANDLE_CLOSE';

function campaignCreate(state, action = {}) {
  switch (action.type) {
    case MSG_TYPE_OPEN_CAMPAIGN_CREATE_PANEL:
      return Object.assign({}, INITIAL_STATE, {
        show: true
      });

    case MSG_TYPE_CREATE_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, {
        failed: false,
        loading: false,
        show: false
      });

    case MSG_TYPE_CREATE_CAMPAIGN_FAILURE:
      return Object.assign({}, state, {
        failed: true,
        loading: false
      });

    case HANDLE_CONFIRM:
      return Object.assign({}, state, {
        failed: false,
        loading: true
      });

    case HANDLE_CLOSE:
      return Object.assign({}, state, {
        show: false
      });

    default:
      return state;
  }
}

export default function useCampaignCreatePanel({
  sendMessage
}) {
  const [state, dispatch] = useReducer(campaignCreate, INITIAL_STATE);
  const handleMessage = useCallback( // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
  ({
    payload
  }) => dispatch(payload), []);
  const onSubmit = useCallback(campaign => {
    sendMessage(MSG_TYPE_CONFIRM_CAMPAIGN_CREATE, {
      campaign
    }); // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.

    dispatch({
      type: HANDLE_CONFIRM
    });
  }, [sendMessage]);
  const onClose = useCallback(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch({
      type: HANDLE_CLOSE
    });
  }, []);
  return Object.assign({}, state, {
    handleMessage,
    onSubmit,
    onClose,
    use: 'create'
  });
}