import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
const ENDPOINT = `${getFullUrl('app-api')}/monetization/v3/request`;
export const sendRequestAccessNotification = apiName => http.post(`${ENDPOINT}/access-owned`, {
  data: {
    apiName
  }
});
export const sendRequestAdditionalSeatsNotification = apiName => http.post(`${ENDPOINT}/additional-seats`, {
  data: {
    apiName
  }
});
export const sendRequestSKUNotification = apiName => http.post(`${ENDPOINT}/sku`, {
  data: {
    apiName
  }
});
export const sendRequestTrialNotification = apiName => http.post(`${ENDPOINT}/trial`, {
  data: {
    apiName
  }
});
export const sendRequestQuotePurchaseNotification = hub => {
  return http.post(`${ENDPOINT}/quote-purchase`, {
    data: {
      hub
    }
  });
};