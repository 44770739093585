import { createSelector } from 'reselect';
import I18n from 'I18n';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsUngatedForSunsetBrandingBanner, getHasLegacyBrandingAccess } from './authSelectors';
const getBrandColorsPayload = basicSelector(state => state.brandSettings.colors);
export const getBrandColors = createSelector(getBrandColorsPayload, colorsPayload => {
  if (colorsPayload && colorsPayload.length > 0) {
    // Return only an array of hex colors, not any other metadata
    return colorsPayload.map(colorObj => colorObj.color);
  }

  return undefined; //empty array will cause nothing to be rendered if you return undefined the favorites section is rendered.
}); // Excludes CMS Free (no legacy branding access -> no action needed)

export const getShouldShowSunsetBrandingBanner = createSelector([getHasLegacyBrandingAccess, getIsUngatedForSunsetBrandingBanner], (hasLegacyBrandingAccess, isUngatedForSunsetBrandingBanner) => {
  const currentMoment = I18n.moment.utc();
  const gracePeriodEndMoment = I18n.moment.utc(1706788800000); // February 1st, 2024

  const gracePeriodHasPassed = currentMoment > gracePeriodEndMoment;
  return isUngatedForSunsetBrandingBanner && hasLegacyBrandingAccess && !gracePeriodHasPassed;
});