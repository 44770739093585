export const USER_ATTRIBUTE_KEYS = {
  HIDE_COLLABORATION_SIDEBAR_EMBEDDED_COMMENTS: 'hideCollaborationSidebarEmbeddedComments',
  COLLABORATION_SIDEBAR_HIDDEN: 'collaborationSidebarHidden',
  COMPLETE_COMMENT_ONBOARDING: 'CollaborationSidebar:CompleteCommentingOnboarding',
  COMMENT_ONBOARDING_ENTRY_COUNT: 'CollaborationSidebar:CommentingOnboardingEntryCount'
};
const COMMENT_ONBOARDING_ENTRY_COUNT_USER_ATTRIBUTE_KEY = 'CollaborationSidebar:CommentingOnboardingEntryCount';
export const getCommentOnboardingEntryCountKey = objectType => `${COMMENT_ONBOARDING_ENTRY_COUNT_USER_ATTRIBUTE_KEY}:${objectType}`;
const ASSET_COMMENTS_LAST_READ = 'CollaborationSidebar:AssetCommentsLastRead';
export const getAssetCommentsLastReadTimestampKey = (objectType, objectId) => `${ASSET_COMMENTS_LAST_READ}:${objectType}:${objectId}`;
export const getUserAttributesArray = (objectType, objectId) => [...Object.values(USER_ATTRIBUTE_KEYS), getCommentOnboardingEntryCountKey(objectType), getAssetCommentsLastReadTimestampKey(objectType, objectId)];