import { createSelector } from 'reselect';
import { intersection } from 'underscore';
import formatName from 'I18n/utils/formatName';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers'; // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message

import { getRenderLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { getIsBlogListingPage, getIsLandingPage, getIsSitePage, getIsBlogPost, getIsEmail } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getPortalHasSitePagesHsSitesDomainAccess, getPortalHasBlogHsSitesDomainAccess // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './po... Remove this comment to see the full error message
} from './portalSelectors';
import { SUPPORTED_HUBS, SUPPORTED_HUBS_REQUIRED_SCOPES } from 'collaboration-sidebar';
// @ts-expect-error Untyped module
import { getHasStagingDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
export const getAuth = basicSelector(state => state.auth);
export const getUser = createSelector([getAuth], auth => auth.user);
export const getGates = createSelector([getAuth], auth => auth.gates);
export const getIsOptedInPatagonia = createSelector([getAuth], auth => auth.isOptedInPatagonia);
export const getScopes = createSelector([getUser], user => user.scopes);
export const getRoles = createSelector([getUser], user => user.roles);
export const createHasScopeSelector = scopeToCheck => createSelector([getScopes], scopes => scopes.indexOf(scopeToCheck) > -1);
export const createHasScopesSelector = scopesToCheck => createSelector([getScopes], scopes => {
  return intersection(scopes, scopesToCheck).length === scopesToCheck.length;
});
export const createHasGateSelector = gateToCheck => createSelector([getGates], gates => gates.indexOf(gateToCheck) > -1); // Never check roles - for access use gates or scopes.

export const createHasRoleSelector = roleToCheck => createSelector([getRoles], roles => roles.indexOf(roleToCheck) > -1);
export const getPortalDetails = createSelector([getAuth], auth => auth.portal);
export const getPortalId = createSelector([getPortalDetails], portal => portal.portal_id);
export const getPortalCreatedAt = createSelector([getPortalDetails], portal => portal.created_at);
export const getCanUseSmartRules = createHasScopeSelector('smartcontent');
export const getIsSuperAdmin = createHasScopeSelector('super-admin');
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserFirstName = createSelector([getUser], user => user.first_name);
export const getUserFullName = createSelector([getUser], user => formatName({
  firstName: user.first_name,
  lastName: user.last_name
}));
export const getUserId = createSelector([getUser], user => user.user_id);
export const getIsUserJitad = createSelector([getUser], user => user.scopes.indexOf('jita-user') > -1);
export const getHasCrmScope = createHasScopeSelector('crm-product');
export const getHasCampaignsReadAccess = createHasScopeSelector('campaigns-access');
export const getHasCampaignsWriteAccess = createHasScopeSelector('campaigns-write');
export const getHasEmailAccessScope = createHasScopeSelector('email-access');
export const getHasEmailRecipientFatigueSuppressionReadScope = createHasScopeSelector('email-recipient-fatigue-suppression-read');
export const getHasEmailCoreApiAccessScope = createHasScopeSelector('email-core-api-access');
export const getHasLandingPagesAccess = createHasScopeSelector('landingpages-access');
export const getHasLandingPagesMultiLangAccess = createHasScopeSelector('landingpages-multilanguage');
export const getHasSitePagesMultiLangAccess = createHasScopeSelector('sitepages-multilanguage');
export const getHasLegacyCtaAccess = createHasScopeSelector('cta-access');
export const getHasWebInteractivesAccess = createHasScopeSelector('calls-to-action-read');
export const getHasCtaAccess = createSelector([getHasLegacyCtaAccess, getHasWebInteractivesAccess], (legacyAccess, webInteractivesAccess) => legacyAccess || webInteractivesAccess);
export const getHasWorkflowsTicketsAccessScope = createHasScopeSelector('workflows-tickets-access');
export const getDomainPageAttachedStylesheetAccess = createHasScopeSelector('domain-page-attached-stylesheet-access');
export const getHasWorkflowsEmailAccessScope = createHasScopeSelector('email-type-workflows-access');
export const getHasWorkflowsEmailWriteAccessScope = createHasScopeSelector('workflows-edit-team-owned');
export const getHasWorkflowsDealsAccessScope = createHasScopeSelector('workflows-platform-deals-access');
export const getHasFormsEmailWriteAccessScope = createHasScopeSelector('forms-write');
export const getHasWorkflowsOrFormsEmailWriteAccessScope = createSelector([getHasWorkflowsEmailWriteAccessScope, getHasFormsEmailWriteAccessScope], (hasWorkflosWriteAccess, hasFormsWriteAccess) => hasWorkflosWriteAccess || hasFormsWriteAccess);
export const getHasSmartContentScope = createHasScopeSelector('smartcontent');
export const getHasWorkflowsQuotesAccessScope = createHasScopeSelector('workflows-quotes-access');
export const getHasDesignManagerAccessScope = createHasScopeSelector('design-manager-access');
export const getHasMABAccess = createHasScopeSelector('content-mab-testing');
export const isSocialAdminUser = createHasScopeSelector('social-all-accounts-configure');
export const isSocialAllAccountsUser = createHasScopeSelector('social-shared-accounts-publish');
export const getHasSocialAccess = createHasScopeSelector('social-access');
export const getHasTopicClusterAccess = createHasScopeSelector('topic-clusters-access');
export const getHasPermissionsToCreateOrEditABTest = createHasScopeSelector('content-ab-testing');
export const getHasHubspotVideoAccess = createHasScopeSelector('marketing-video');
export const getHasEitherTestCreationAccess = createSelector([getHasMABAccess, getHasPermissionsToCreateOrEditABTest], (hasMABAccess, hasABAccess) => hasMABAccess || hasABAccess);
export const getHasGlobalContentEditorAccess = createHasScopeSelector('global-content-write-access');
export const getCanContactSupport = createHasScopeSelector('zorse-webticket');
export const getHasAdvancedLandingPagesWriteScope = createHasScopeSelector('landingpages-advanced-write');
export const getHasSitePagesWriteScope = createHasScopeSelector('pages-write');
export const getHasBlogListingPagesWriteScope = createHasScopeSelector('blog-listing-page-write');
export const getHasThemeGlobalSettingsScope = createHasScopeSelector('website-theme-write-access');
export const getHasMultiLangAccess = createHasScopeSelector('cms-multi-language');
export const getIsUngatedToMergeTemplateSchemaBodyIntoContentBody = createHasGateSelector('ContentEditorUI:MergeTemplateSchemaBodyIntoContentBody');
export const getIsUngatedToRepositionPostBodyModules = createHasGateSelector('Blog:RepositionPostBodyModules');
export const getIsUngatedForDataTab = createHasGateSelector('ContentEditorUI:DataTab');
export const getHasTinyMCEParsedFonts = createHasGateSelector('ContentEditorUI:TinyMCEParsedFonts');
export const getHasTinyMCEParsedFontsEnabled = createHasGateSelector('ContentEditorUI:TinyMCEParsedFontsEnabled');
export const getIsUngatedForInlineDataTokenEditing = createHasGateSelector('ContentEditorUI:InlineDataTokenEditing');
export const getHasMeetingsPersonalCreateAccess = createHasScopeSelector('meetings-personal-link-create');
export const getHasMeetingsTeamCreateAccess = createHasScopeSelector('meetings-team-link-create');
export const getHasUniversalEditAccess = createHasScopeSelector('meetings-edit-all-links');
export const getHasBlogSchedulingUpdateAccess = createSelector([getScopes], scopes => scopes.includes('blog-publish-scheduled-update'));
export const getHasPagesSchedulingUpdateAccess = createSelector([getIsSitePage, getScopes], (isSitePage, scopes) => scopes.includes(`${isSitePage ? 'pages' : 'landingpages'}-publish-scheduled-update`));
export const getHasPartnerMatchingAppAccess = createSelector([getScopes, getRenderLanguage], (scopes, renderLanguage) => scopes.indexOf('partner-matching-app-access') > -1 && renderLanguage === 'en');
export const getHasAccountTrustScope = createHasScopeSelector('landingpages-trusted');
export const getHasAccessToUrlRedirects = createHasScopeSelector('cms-url-redirects-read');
export const getIsUngatedForMergeInDefaultValues = createHasGateSelector('ContentRendering:MergeInDefaultValues');
export const getIsUngatedForContentTypes = createHasGateSelector('CMv2:UseContentTypes');
export const getHasPaymentsReadAccess = createHasScopeSelector('payment-links-read');
export const getIsUngatedForModuleVisibility = createHasGateSelector('ContentEditorUI:VisibilityModule');
export const getIsUngatedForNewEntryPoint = createHasGateSelector('ContentEditorUI:EntryPoint');
export const getIsUngatedForCartPersonalizationToken = createHasGateSelector('Email:CartPersonalizationToken');
export const getIsUngatedForCartSync = createHasGateSelector('Integrations:OpsHub:CartSync');
export const getHasPersonalizationAccess = createHasScopeSelector('personalization-access');
export const getHasWebsitePagesNoBranding = createHasScopeSelector('website-pages-no-branding');
export const getHasLandingPagesNoBranding = createHasScopeSelector('landingpages-no-branding');
export const getHasBlogPagesNoBranding = createHasScopeSelector('blog-pages-no-branding'); // The Remove Branding popups should only appear if they have the CMS Free product.
// We can check this by looking for the landingpages-no-branding,
// website-pages-no-branding, and blog-pages-no-branding scopes.

export const getShouldShowRemoveBrandingMessage = createSelector([getIsBlogListingPage, getIsBlogPost, getHasBlogPagesNoBranding, getIsLandingPage, getHasLandingPagesNoBranding, getIsSitePage, getHasWebsitePagesNoBranding], (isBlogListingPage, isBlogPost, hasBlogPagesNoBranding, isLandingPage, hasLandingPagesNoBranding, isSitePage, hasWebsitePagesNoBranding) => (isBlogListingPage || isBlogPost) && !hasBlogPagesNoBranding || isLandingPage && !hasLandingPagesNoBranding || isSitePage && !hasWebsitePagesNoBranding);
export const getIsUserUngatedForPatagonia = createHasGateSelector('ContentEditorUI:PatagoniaRedesign');
export const getIsUngatedForDndAreasInCustomEditor = createHasGateSelector('Email:DndAreasInCustomEditor');
export const getIsUngatedForSidebarContentTree = createHasGateSelector('Email:ContentTreeSidebar');
export const getIsProductIncompatibleWithPrerendering = createSelector([getHasAdvancedLandingPagesWriteScope, getPortalHasSitePagesHsSitesDomainAccess, getPortalHasBlogHsSitesDomainAccess, getIsLandingPage, getIsSitePage, getIsBlogPost], (hasAdvancedLandingPagesWriteScope, portalHasSitePagesHsSitesDomainAccess, portalHasBlogHsSitesDomainAccess, isLandingPage, isSitePage, isBlogPost) => {
  if (isLandingPage) return hasAdvancedLandingPagesWriteScope;
  if (isSitePage) return portalHasSitePagesHsSitesDomainAccess;
  if (isBlogPost) return portalHasBlogHsSitesDomainAccess;
  return false;
});
export const getHasDndTemplateAccess = createHasScopeSelector('cms-dnd-templates');
const getHasCMSHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.CMS_HUB]);
const getHasMarketingHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.MARKETING_HUB]);
export const getHasCommenting = createSelector([getIsEmail, getHasCMSHubCommenting, getHasMarketingHubCommenting], (isEmail, hasCMSHubCommenting, hasMarketingHubCommenting) => isEmail ? hasMarketingHubCommenting : hasCMSHubCommenting);
export const getHasReadAccessToProductApprovals = createSelector([getIsLandingPage, getIsSitePage, getIsBlogPost, getIsEmail, getScopes, getHasStagingDomain], (isLandingPage, isSitePage, isBlogPost, isEmail, scopes, isStagedContent) => {
  if (!isStagedContent) {
    if (isLandingPage) {
      return scopes.includes('landingpages-approval-read');
    }

    if (isSitePage) {
      return scopes.includes('pages-approval-read');
    }

    if (isBlogPost) {
      return scopes.includes('blog-approval-read');
    }

    if (isEmail) {
      return scopes.includes('email-approval-read');
    }
  }

  return false;
});
export const getHasWriteAccessToProductApprovals = createSelector([getIsLandingPage, getIsSitePage, getIsBlogPost, getIsEmail, getScopes], (isLandingPage, isSitePage, isBlogPost, isEmail, scopes) => {
  if (isLandingPage) {
    return scopes.includes('landingpages-approval-write');
  }

  if (isSitePage) {
    return scopes.includes('pages-approval-write');
  }

  if (isBlogPost) {
    return scopes.includes('blog-approval-write');
  }

  if (isEmail) {
    return scopes.includes('email-approval-write');
  }

  return false;
});
export const getHasPrivateContentAccess = createHasScopeSelector('marketing-content-restricted-write');
export const getHasCMSPrivateContentPublishScope = createHasScopeSelector('cms-private-content-publish');
export const getIsUngatedForPatagonia = createSelector([getIsUserUngatedForPatagonia, getIsOptedInPatagonia], (isUserUngatedForPatagonia, isOptedInPatagonia) => {
  return isUserUngatedForPatagonia && isOptedInPatagonia;
});
export const getShouldUsePatagoniaVariationSelectors = createSelector([getIsUngatedForPatagonia, getHasReadAccessToProductApprovals], (isUngatedForPatagonia, hasReadAccessToProductApprovals) => isUngatedForPatagonia || hasReadAccessToProductApprovals);
export const getIsUngatedForFixedLayoutSections = createHasGateSelector('CMS:FixedLayoutSections');
export const getIsUngatedForBackgroundLayers = createHasGateSelector('ContentEditorUI:BackgroundLayers');
export const getHasPreventTinymceTransformsOnUndo = createHasGateSelector('ContentEditorUI:PreventTinymceTransformsOnUndo');
export const getHasSocialComposer = createHasGateSelector('Blog:SocialIntegration');
export const getIsUngatedForCMSGenerativeImages = createHasGateSelector('CMS:GenerativeAIImages');
export const getIsUngatedForFlexAreasAndNewSidebar = createSelector([getIsUngatedForSidebarContentTree, getIsUngatedForDndAreasInCustomEditor], (isUngatedForSidebarContentTree, isUngatedForDndAreasInCustomEditor) => isUngatedForSidebarContentTree && isUngatedForDndAreasInCustomEditor);
export const getIsUngatedForFloatingCollaborationSidebar = createHasGateSelector('MO:CollaborationSidebar:FloatingSidebar:All');
export const getIsUngatedForMembershipsBlogDataWall = createHasGateSelector('ContentMembership:BlogDataWall');
export const getIsUngatedForFollowupEmails = createHasGateSelector('ContextualAutomation:FollowupEmailInFormField');
export const getIsUngatedForAsyncScreenshotCreation = createHasGateSelector('ContentEditorUI:AsyncScreenshots');
export const getIsUngatedForModuleFieldEditing = createHasGateSelector('ContentEditorUI:InlineModuleFieldEditing');
export const getIsUngatedForPublishImmediatelyFlowOnly = createHasGateSelector('ContentEditors:PublishImmediatelyFlow');
export const getIsUngatedForPublishImmediatelyFlow = createSelector([getIsUngatedForPatagonia, getIsUngatedForPublishImmediatelyFlowOnly], (isUngatedForPatagonia, isUngatedForPublishImmediatelyFlowOnly) => isUngatedForPatagonia && isUngatedForPublishImmediatelyFlowOnly);
export const getIsUngatedForNewSectionsEndpointsPart2 = createHasGateSelector('CMS:MarketerSectionCogMigrationPart2');

const _getIsUngatedForAutotranslation = createHasGateSelector('CMS:Autotranslation');

const _getIsUngatedForContentEditorApiV2 = createHasGateSelector('ContentEditor:ApiV2');

export const getIsUngatedForAutotranslation = createSelector([_getIsUngatedForAutotranslation, _getIsUngatedForContentEditorApiV2], (isUngatedForAutotranslation, isUngatedForContentEditorApiV2) => isUngatedForAutotranslation && isUngatedForContentEditorApiV2);
export const getIsUngatedForBackgroundImageClampSizes = createHasGateSelector('ContentEditorUI:BackgroundImageClampSizes');
export const getPortalIsUngatedForPublishPrivateContentPermissions = createHasGateSelector('UserManagement:PublishPrivateContent');
export const getUserHasPublishPrivateContentAccess = createSelector([getHasCMSPrivateContentPublishScope, getPortalIsUngatedForPublishPrivateContentPermissions], (hasCMSPrivateContentPublishScope, portalIsUngatedForPublishPrivateContentPermissions) => portalIsUngatedForPublishPrivateContentPermissions ? hasCMSPrivateContentPublishScope : true);
export const getIsUngatedForSunsetBrandingBanner = createHasGateSelector('SunsetLegacyBranding:90DayAlert');
export const getHasLegacyBrandingAccess = createHasScopeSelector('legacy-branding-access');