export default class Row {
  constructor(treeRef, {
    name,
    columnNames = [],
    parentCellName,
    value
  }) {
    this._name = name;
    this._value = value;
    this._columnNames = columnNames;
    this._parentCellName = parentCellName;
    this._treeRef = treeRef;
  }

  cloneAndChange({
    name,
    columnNames,
    parentCellName,
    treeRef,
    value
  }) {
    name = name || this._name;
    treeRef = treeRef || this._treeRef;
    columnNames = columnNames != null ? columnNames : this._columnNames;
    parentCellName = parentCellName != null ? parentCellName : this._parentCellName;
    value = value != null ? value : this._value;
    return treeRef.createNewRow({
      treeRef,
      name,
      columnNames,
      parentCellName,
      value
    });
  }

  createFullClone(params) {
    return this.cloneAndChange(params);
  }

  getName() {
    return this._name;
  }

  isRow() {
    return true;
  }

  isCell() {
    return false;
  }

  isStaticSection() {
    return false;
  }

  getRootName() {
    return this._treeRef.getRootName();
  }

  isRoot() {
    return false;
  }

  getColumnNames() {
    return this._columnNames;
  }

  getNumberColumns() {
    return this._columnNames.length;
  }

  getParentName() {
    return this._parentCellName;
  }

  getParent() {
    return !!this._parentCellName && this._treeRef.findCell(this._parentCellName);
  }

  getColumns() {
    return this._columnNames.map(c => this._treeRef.findCell(c));
  }

  hasColumns() {
    return this._columnNames.length > 0;
  }

  hasValue() {
    return this._value != null;
  }

  getValue() {
    return this._value;
  }

  indexOfColumn(columnName) {
    return this.getColumnNames().indexOf(columnName);
  } // Added so subclasses can implement behavior to prevent specific rows (or cells) from being
  // automatically deleted when their sole child is deleted


  shouldAutoDeleteWhenEmpty() {
    return true;
  }

}