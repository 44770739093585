import { FETCH_SUPPORTED_LANGUAGES_SUCCEEDED, FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_RENDER_LANGUAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
import produce from 'immer';
const initialState = {
  supportedLanguages: [],
  renderLanguage: null
};
const INITIAL_STATE = Immutable.fromJS(initialState);

const languageReducer = (state = INITIAL_STATE, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_SUPPORTED_LANGUAGES_SUCCEEDED:
      return state.merge({
        supportedLanguages: payload
      });

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge({
        renderLanguage: response.render_language
      });

    case UPDATE_RENDER_LANGUAGE:
      return state.merge({
        renderLanguage: payload
      });

    default:
      return state;
  }
};

export const languageImmerReducer = (state = initialState, action) => produce(state, draft => {
  const {
    payload,
    type,
    response
  } = action;

  switch (type) {
    case FETCH_SUPPORTED_LANGUAGES_SUCCEEDED:
      draft.supportedLanguages = payload;
      return draft;

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      draft.renderLanguage = response.render_language;
      return draft;

    case UPDATE_RENDER_LANGUAGE:
      draft.renderLanguage = payload;
      return draft;

    default:
      return draft;
  }
});
export default languageReducer;