import { useCallback, useEffect, useRef } from 'react';
import { fetchAssetLastUpdatedAtOrDefault } from '../api/Commenting';
import { LIVE_COMMENTS, UNREAD_COMMENTS } from '../constants/gates';
import { isUngated } from '../utils/auth';
import usePageIsVisible from './useIsPageVisible';
import { getAssetKeyFromAsset } from 'collaboration-sidebar/utils/multipleAssets';
export const POLL_INTERVAL_SIDEBAR_OPEN = 5000;
export const POLL_INTERVAL_SIDEBAR_CLOSED = 20000;
export default function usePollForCommentsChanges({
  objectId,
  objectType,
  onUpdateCollaborationSidebarState,
  hasSidebarPermission,
  assetsLastUpdated,
  isOpen
}) {
  const pageIsVisible = usePageIsVisible();
  const interval = useRef(); // Use a ref instead of prop to use live value in setInterval.

  const assetsLastUpdatedRef = useRef(assetsLastUpdated);
  useEffect(() => {
    assetsLastUpdatedRef.current = assetsLastUpdated;
  });
  const fetchLastUpdated = useCallback(objectIdArg => {
    fetchAssetLastUpdatedAtOrDefault({
      objectId: objectIdArg,
      objectType
    }).then(({
      updatedAt
    }) => {
      const assetKey = getAssetKeyFromAsset({
        objectId: objectIdArg,
        objectType
      });

      if (updatedAt !== assetsLastUpdatedRef.current[assetKey]) {
        onUpdateCollaborationSidebarState({
          assetsLastUpdated: Object.assign({}, assetsLastUpdatedRef.current, {
            [assetKey]: updatedAt
          })
        });
      }
    }).catch(() => {// do nothing. Polling shouldn't be visible to user
    });
  }, [objectType, onUpdateCollaborationSidebarState]);
  useEffect(() => {
    if (!objectId) {
      return () => {};
    }

    if (isUngated(UNREAD_COMMENTS) && isUngated(LIVE_COMMENTS) && objectId && objectType && hasSidebarPermission && pageIsVisible) {
      fetchLastUpdated(objectId);
      interval.current = setInterval(() => fetchLastUpdated(objectId), isOpen ? POLL_INTERVAL_SIDEBAR_OPEN : POLL_INTERVAL_SIDEBAR_CLOSED);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [objectType, objectId, pageIsVisible, hasSidebarPermission, isOpen, fetchLastUpdated]);
}