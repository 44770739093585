import { combineReducers } from 'redux';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { CUSTOM_FONTS_CREATE_FONT, CUSTOM_FONTS_FETCH_FONT, CUSTOM_FONTS_FETCH_FONTS, CUSTOM_FONTS_BY_ASSET_FETCH_FONTS, CUSTOM_FONTS_FILE_UPLOAD, GOOGLE_FONTS_FETCH_FONT, GOOGLE_FONTS_FETCH_FONTS, GOOGLE_FONTS_FETCH_COMMON_FONTS, GOOGLE_FONTS_CLEAR, SELECT_CUSTOM_FONT, SELECT_GOOGLE_FONT, CUSTOM_FONTS_STYLE_ASSIGNMENT } from 'ContentData/actions/ActionTypes';

const fonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case GOOGLE_FONTS_CLEAR:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

const selectedFonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_GOOGLE_FONT:
      {
        const {
          font
        } = action;
        const fontName = 'family' in font ? font.family : font.name;
        return Object.assign({}, state, {
          [fontName]: font
        });
      }

    default:
      return state;
  }
};

const commonFonts = (state = [], action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results;
      }

    default:
      return state;
  }
};

const commonFontsRequestStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
};

const customFontsByAsset = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          response,
          options
        } = action;
        const {
          path
        } = options || {};
        if (!(response && response.length && path)) return state;
        const fontsObj = response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state[path]));
        return Object.assign({}, state, {
          [path]: fontsObj
        });
      }

    default:
      return state;
  }
};

const customFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.name] = font;
          return hash;
        }, Object.assign({}, state));
      }

    default:
      return state;
  }
};

const selectedCustomFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.name]: response
        });
      }

    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_CUSTOM_FONT:
      {
        const {
          font
        } = action;
        const fontName = 'family' in font ? font.family : font.name;
        return Object.assign({}, state, {
          [fontName]: font
        });
      }

    default:
      return state;
  }
};

const customFontsByAssetRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          error,
          options,
          response
        } = action;
        const {
          path
        } = options || {};
        if (!path) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [path]: status
        });
      }

    default:
      return state;
  }
};

const customFontsRequestStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          error,
          response
        } = action;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return status;
      }

    default:
      return state;
  }
};

const uploadedCustomFont = (state = null, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_CREATE_FONT:
      {
        const {
          response
        } = action;
        if (!response || !response.id) return state;
        return response;
      }

    default:
      return state;
  }
};

const createCustomFontRequestStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_CREATE_FONT:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
};

const uploadedCustomFontFiles = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FILE_UPLOAD:
      {
        const {
          response,
          fileName
        } = action;
        if (!response || !response.objects || !response.objects.length) return state;
        const file = response.objects[0];
        return Object.assign({}, state, {
          [fileName]: file
        });
      }

    default:
      return state;
  }
};

const uploadCustomFontFileRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FILE_UPLOAD:
      {
        const {
          error,
          fileName,
          response
        } = action;
        if (!fileName) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [fileName]: status
        });
      }

    default:
      return state;
  }
};

const uploadCustomFontStyleAssignmentRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_STYLE_ASSIGNMENT:
      {
        const {
          error,
          styleName,
          response
        } = action;
        if (!styleName) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [styleName]: status
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  fonts,
  selectedCustomFonts,
  selectedFonts,
  commonFonts,
  commonFontsRequestStatus,
  customFontsByAsset,
  customFontsByAssetRequestStatus,
  customFonts,
  customFontsRequestStatus,
  createCustomFontRequestStatus,
  uploadCustomFontFileRequestStatus,
  uploadedCustomFont,
  uploadedCustomFontFiles,
  uploadCustomFontStyleAssignmentRequestStatus
});