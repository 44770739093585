export const accountId = 53;
export const debugKey = 'USAGE_TRACKER_JS';
export const hstcKey = '__hstc';
export const utkKey = 'hubspotutk';
export const hamplitudeKey = '__hmpl';
/** @deprecated this is here for legacy compatibility */

export const recorderEnabled = 'USAGE_TRACKER_JS_RECORDER_ENABLED';
/** @deprecated this is here for legacy compatibility */

export const recordedEvents = 'USAGE_TRACKER_JS_RECORDED_EVENTS';
/** @deprecated this is here for legacy compatibility */

export const recordedEventKeys = 'USAGE_TRACKER_JS_RECORDED_EVENT_KEYS';