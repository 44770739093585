'use es6';

import IframeStylesManager from './IframeStylesManager';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PANELS_WITHOUT_SUBCATEGORY } from 'ContentEditorUI/constants/Sidebar';
import { PATAGONIA_NAVIGATION_BAR_WIDTH } from 'ContentEditorUI/lib/sidebar/constants';
import { getSidebarCategory, getSidebarSearchQuery } from 'ContentEditorUI/redux/selectors/sidebarSelectors';
const TOTAL_VEIL_ANIMATION_DURATION = 350;
const VEIL_ANIMATION_DELAY = 100;
const TOOLBAR_WIDTH = PATAGONIA_NAVIGATION_BAR_WIDTH;
const CATEGORY_WIDTH = 172;
const SUB_CATEGORY_WIDTH = 190;
const categoryToSidebarIframeWidthMap = {
  [PATAGONIA_ADD_PANEL_CATEGORIES.MODULES]: 378,
  [PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS]: 378,
  [PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS]: 378
};
const iframeVeilOffset = Math.floor((1 - VEIL_ANIMATION_DELAY / TOTAL_VEIL_ANIMATION_DURATION) * 100) / 100;
const iframeVeilKeyframes = [{
  opacity: 1
}, // This represents the delay in the animation
{
  opacity: 1,
  offset: iframeVeilOffset,
  easing: 'ease-in'
}, {
  opacity: 0
}];
const iframeVeilKeyframesWithoutHidingIframe = [{
  opacity: 1
}, // This represents the delay in the animation
{
  opacity: 1,
  offset: 0.7,
  easing: 'ease-in'
}, {
  opacity: 0
}];
const iframeVeilKeyframesOptions = {
  duration: TOTAL_VEIL_ANIMATION_DURATION
};

const makeIdForVeil = iframeId => {
  if (iframeId) {
    return `iframe-veil-${iframeId}`;
  }

  const pseudoRandomId = Math.floor(performance.now() + Math.floor(Math.random() * 10000));
  return `iframe-veil-${pseudoRandomId}`;
};

class SidebarIframeStylesManager extends IframeStylesManager {
  constructor() {
    super();
    this._iframeVeilElement = void 0;
    this._iframeVeilId = void 0;
    this._iframeVeilAnimation = void 0;
    this._iframeVeilTimeout = void 0;
    this._toggleAnimationInProgress = false;
    this.iframeElement = void 0;
    this._initialSidebarIframeWrapperWidth = void 0;
    this._finalSidebarIframeWrapperWidth = void 0;
    this._currentCategoryWidth = 0;
    this.showIframeElement = this.showIframeElement.bind(this);
    this.onWidthAnimationEnd = this.onWidthAnimationEnd.bind(this);
    this.onWidthAnimationStart = this.onWidthAnimationStart.bind(this);
    this.onWidthAnimatonFrame = this.onWidthAnimatonFrame.bind(this);
    this.hideVeil = this.hideVeil.bind(this);
    this.setUpVeilAnimation = this.setUpVeilAnimation.bind(this);
    this.setUpVeilTimeout = this.setUpVeilTimeout.bind(this);
    this._hadSearchQuery = false;
  }

  init(wrapperElement, iframeElement) {
    super.init(wrapperElement);
    this.iframeElement = iframeElement;
  }

  showIframeElement() {
    window.requestAnimationFrame(() => {
      this.iframeElement.style.visibility = 'unset';
    });
  }

  instantiateVeilElement() {
    const iframeId = this.iframeElement.getAttribute('id');
    this._iframeVeilId = makeIdForVeil(iframeId);
    this._iframeVeilElement = document.createElement('div');

    this._iframeVeilElement.setAttribute('id', this._iframeVeilId);

    this._iframeVeilElement.style.display = 'none';

    this._iframeVeilElement.classList.add('iframe-white-veil-base');

    this.iframeWrapperElement.insertAdjacentElement('afterbegin', this._iframeVeilElement);
  }

  hideVeil() {
    window.requestAnimationFrame(() => {
      this._iframeVeilElement.style.display = 'none';
    });
  }

  maybeClearExistingVeilAnimationParams() {
    if (this._iframeVeilTimeout) {
      clearTimeout(this._iframeVeilTimeout);
      this._iframeVeilTimeout = null;
    }

    if (this._iframeVeilAnimation) {
      this._iframeVeilAnimation.cancel();

      this._iframeVeilAnimation = null;
    }
  }

  setUpVeilAnimation(hideFrame) {
    const iframeVeilFrames = hideFrame ? iframeVeilKeyframes : iframeVeilKeyframesWithoutHidingIframe;
    this._iframeVeilAnimation = this._iframeVeilElement.animate(iframeVeilFrames, iframeVeilKeyframesOptions);
  }

  setUpVeilTimeout() {
    this._iframeVeilTimeout = setTimeout(() => {
      this.hideVeil();
      this._iframeVeilAnimation = null;
      this._iframeVeilTimeout = null;
    }, TOTAL_VEIL_ANIMATION_DURATION + 5);
  }

  animateCoveringVeil(hideFrame) {
    if (!this.iframeWrapperElement) {
      console.error('Cannot use SidebarIframeStylesManager before initialization');
      return;
    }

    if (!this._iframeVeilElement) {
      this.instantiateVeilElement();
    }

    this.maybeClearExistingVeilAnimationParams();
    window.requestAnimationFrame(() => {
      this._iframeVeilElement.style.display = 'block';
      this.setUpVeilAnimation(hideFrame);
      this.setUpVeilTimeout();

      if (hideFrame) {
        this.iframeElement.style.visibility = 'hidden';
      }
    });
  }

  onWidthAnimationStart(sidebarWidth) {
    const reduxState = window.hubspot.ContentEditorUI.store.getState();
    const category = getSidebarCategory(reduxState);
    const finalSidebarWidth = categoryToSidebarIframeWidthMap[category];
    const searchQuery = getSidebarSearchQuery(reduxState);
    const cameFromSearch = this._hadSearchQuery && !searchQuery;

    if (Boolean(sidebarWidth) && (this._cameFromPanelWithoutSubCategory || cameFromSearch)) {
      //expanding back to panel with sub category menu when coming from without sub categories
      //since the sub category menu is being shown again after being hidden we set the intial sidebar width to
      //account that and add a white veil for smoother transition
      this._initialSidebarIframeWrapperWidth = sidebarWidth - CATEGORY_WIDTH - SUB_CATEGORY_WIDTH;
      window.requestAnimationFrame(() => {
        this.iframeWrapperElement.style.width = `${this._initialSidebarIframeWrapperWidth}px`;
      });
      this._finalSidebarIframeWrapperWidth = finalSidebarWidth;
      this.animateCoveringVeil(false);
      this._hadSearchQuery = Boolean(searchQuery);
      this._cameFromPanelWithoutSubCategory = PANELS_WITHOUT_SUBCATEGORY.includes(category);
    } else {
      this._hadSearchQuery = Boolean(searchQuery);
      this._initialSidebarIframeWrapperWidth = this.iframeWrapperElement.getBoundingClientRect().width;
      this._finalSidebarIframeWrapperWidth = finalSidebarWidth;
      this._cameFromPanelWithoutSubCategory = PANELS_WITHOUT_SUBCATEGORY.includes(category);
    }
  }

  onWidthAnimatonFrame(easingCoefficient) {
    if (!easingCoefficient) {
      return;
    }

    const sidebarWidthAdjustment = (this._initialSidebarIframeWrapperWidth - this._finalSidebarIframeWrapperWidth) * easingCoefficient;
    const newSidebarWidth = this._initialSidebarIframeWrapperWidth - sidebarWidthAdjustment;
    this.iframeWrapperElement.style.width = `${newSidebarWidth}px`;
  }

  onWidthAnimationEnd() {
    this.iframeWrapperElement.style.width = `${this._finalSidebarIframeWrapperWidth}px`;
    this._finalSidebarIframeWrapperWidth = null;
    this._initialSidebarIframeWrapperWidth = null;
  }

  onSidebarToggleAnimationStart(currentCategory) {
    this._currentCategoryWidth = categoryToSidebarIframeWidthMap[currentCategory];
    this._toggleAnimationInProgress = true;
  }

  onSidebarToggleAnimationFrame(newSidebarWidth) {
    if (this.iframeWrapperElement.style.width !== `${this._currentCategoryWidth}px`) {
      this.iframeWrapperElement.style.width = `${this._currentCategoryWidth}px`;
    }

    this.iframeWrapperElement.style.left = `${newSidebarWidth - this._currentCategoryWidth + TOOLBAR_WIDTH}px`;
  }

  onSidebarTogleAnimationEnd() {
    this._toggleAnimationInProgress = false;
    this._currentCategoryWidth = 0;
  }

  getIsAnimationInProgress() {
    return this._toggleAnimationInProgress;
  }

  requestIframeStyleChangesFromProxy(styleObjects) {
    if (this.getIsAnimationInProgress()) {
      return;
    }

    if (styleObjects) {
      window.requestAnimationFrame(() => {
        styleObjects.forEach(styleObject => {
          const {
            styleKey,
            styleValue
          } = styleObject;

          if (!this.iframeWrapperElement) {
            return;
          }

          this.iframeWrapperElement.style.setProperty(styleKey, styleValue);
        });
      });
    }
  }

}

const SidebarIframeStylesManagerInstance = new SidebarIframeStylesManager();
export default SidebarIframeStylesManagerInstance;