// Please update the Google Doc when making changes to the events:
// https://docs.google.com/document/d/1eYOmuhbzxhDE9xFa38qY5tEarAmroD8WYh8JE0dFzhk/edit?usp=sharing
// APPROVAL MODAL
export const APPROVAL_MODAL_OPENED = 'approval-modal-opened';
export const APPROVAL_MODAL_CLOSED_WITHOUT_SUBMISSION = 'approval-modal-closed-without-submission';
export const APPROVAL_MODAL_FORM_SUBMITTED = 'approval-modal-form-submitted';
export const APPROVAL_MODAL_CHANGED_APPROVER = 'approval-modal-changed-approver'; // APPROVAL SIDEBAR

export const APPROVAL_SIDEBAR_OPENED = 'approval-sidebar-opened';
export const APPROVAL_SIDEBAR_ACTIVITY_SECTION_OPENED = 'approval-sidebar-section-opened'; // APPROVAL BUTTONS

export const APPROVAL_REQUEST_CHANGES = 'approval-request-changes'; // APPROVAL EDIT PANEL

export const APPROVAL_EDIT_PANEL_OPENED = 'approval-edit-panel-opened';
export const APPROVAL_EDIT_PANEL_CANCELLED = 'approval-edit-panel-cancelled';
export const APPROVAL_EDIT_PANEL_SAVED = 'approval-edit-panel-saved'; // REQUEST ACCESS TO APPROVALS

export const APPROVAL_REQUEST_ACCESS_MODAL_OPENED = 'approval-request-access-modal-opened';
export const APPROVAL_REQUEST_ACCESS_FORM_SUBMITTED = 'approval-request-access-form-submitted';
export const APPROVAL_REQUEST_ACCESS_ADMIN_GO_TO_SETTINGS_BUTTON = 'approval-request-access-admin-go-to-settings-button';