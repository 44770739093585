// Api proxy definitions
const COMPOSITION_GOOGLE = 'composition/v1/googledrive';
export const ApiMap = {
  BLOGS: {
    getUrl: () => `blog-settings/v1/blogs`,
    regex: new RegExp('^blog-settings/v1/blogs$'),
    methods: ['GET']
  },
  CTAS: {
    getUrl: guid => `ctas/v3/placements/${guid}`,
    regex: new RegExp('^ctas/v3/placements/[^/]+'),
    methods: ['GET']
  },
  FILE_INFO: {
    getUrl: filePath => `filemanager/api/v2/files/info/${filePath}`,
    regex: new RegExp('^filemanager/api/v2/files/info/.*'),
    methods: ['GET']
  },
  FILE_RESOLVE: {
    getUrl: () => 'filemanager/api/v2/files/resolve',
    regex: new RegExp('^filemanager/api/v2/files/resolve'),
    methods: ['GET']
  },
  FILE_UPLOAD_URL: {
    getUrl: () => `filemanager/api/v3/files/synchronous-download-from-url`,
    regex: new RegExp('^filemanager/api/v3/files/synchronous-download-from-url'),
    methods: ['POST']
  },
  FORMS: {
    getUrl: guid => `forms/v2/forms/${guid}`,
    regex: new RegExp('^forms/v2/forms/[^/]+'),
    methods: ['GET']
  },
  FORMS_LIST: {
    getUrl: () => `forms/v2/forms`,
    regex: new RegExp('^forms/v2/forms'),
    methods: ['GET']
  },
  MEETINGS: {
    getUrl: () => `meetings/v2/link/portal`,
    regex: new RegExp('^meetings/v2/link/portal$'),
    methods: ['GET']
  },
  MEETINGS_SEARCH: {
    getUrl: slug => `meetings/v1/link/slug/${slug}`,
    regex: new RegExp('^meetings/v1/link/slug/[^/]+'),
    methods: ['GET']
  },
  PAGES_CONTENT: {
    getUrl: () => `content/api/v4/contents`,
    regex: new RegExp('^content/api/v4/contents$'),
    methods: ['GET']
  },
  PAGES_SEARCH: {
    getUrl: () => `contentsearch/v2/search/internal`,
    regex: new RegExp('^contentsearch/v2/search/internal$'),
    methods: ['GET']
  },
  SNIPPETS: {
    getUrl: () => `salescontentsearch/v2/search`,
    regex: new RegExp('^salescontentsearch/v2/search$'),
    methods: ['POST']
  },
  USERS: {
    getUrl: () => `users/v2/app/hub-users`,
    regex: new RegExp('^users/v2/app/hub-users$'),
    methods: ['GET']
  },
  USER_SETTINGS: {
    getUrl: () => `users/v1/app/attributes`,
    regex: new RegExp('^users/v1/app/attributes'),
    methods: ['GET', 'POST']
  },
  VIDEO_PLAYER: {
    getUrl: playerId => `filemanager/api/v3/players/${playerId}/videos/file-metadata`,
    regex: new RegExp('^filemanager/api/v3/players/[^/]+'),
    methods: ['GET']
  },
  VIDEO: {
    getUrl: videoId => `video/v1/videos/${videoId}`,
    regex: new RegExp('^video/v1/videos/[^/]+'),
    methods: ['GET']
  },
  PROPERTIES: {
    getUrl: category => `properties/v4/${category}`,
    regex: new RegExp('^properties/v4/[^/]+'),
    methods: ['GET']
  },
  PATHS: {
    getUrl: () => `data-tokens/v1`,
    regex: new RegExp('^data-tokens/v1$'),
    methods: ['POST']
  },
  CUSTOM_OBJECT_META: {
    getUrl: () => 'crm-object-schemas/v3/schemas/',
    regex: new RegExp('^crm-object-schemas/v3/schemas/'),
    methods: ['GET']
  },
  OEMBED_PROXY: {
    getUrl: () => `oembedproxy/v1/oembed`,
    regex: new RegExp('^oembedproxy/v1/oembed$'),
    methods: ['GET']
  },
  OWNERS: {
    getUrl: () => `owners/v2/owners`,
    regex: new RegExp('^owners/v2/owners$'),
    methods: ['GET']
  },
  SITE_SETTINGS: {
    getUrl: portalId => `content-settings/v1/site-settings/by-portal-id/${portalId}`,
    regex: new RegExp('^content-settings/v1/site-settings/by-portal-id/[^/]+'),
    methods: ['GET']
  },
  PORTAL_CURRENCY: {
    getUrl: () => `multi-currency/v1/currencies/information`,
    regex: new RegExp('^multi-currency/v1/currencies/information'),
    methods: ['GET']
  },
  BRAND_COLORS: {
    getUrl: () => `branding/v1/colors`,
    regex: new RegExp('^branding/v1/colors'),
    methods: ['GET']
  },
  GOOGLE_AUTH_INFO: {
    getUrl: () => `${COMPOSITION_GOOGLE}/auth-info`,
    regex: new RegExp(`^${COMPOSITION_GOOGLE}/auth-info`),
    methods: ['GET']
  },
  GOOGLE_AUTH_TOKEN: {
    getUrl: () => `${COMPOSITION_GOOGLE}/default-auth-info`,
    regex: new RegExp(`^${COMPOSITION_GOOGLE}/default-auth-info`),
    methods: ['GET']
  },
  GOOGLE_DOC_METADATA: {
    getUrl: fileId => `${COMPOSITION_GOOGLE}/${fileId}/metadata`,
    regex: new RegExp(`^${COMPOSITION_GOOGLE}/[^/]+/metadata`),
    methods: ['GET']
  },
  IMPORT_GOOGLE_DOC: {
    getUrl: fileId => `${COMPOSITION_GOOGLE}/${fileId}/to-composition`,
    regex: new RegExp(`^${COMPOSITION_GOOGLE}/[^/]+/to-composition`),
    methods: ['POST']
  },
  DISCONNECT_GOOGLE_ACCOUNT: {
    getUrl: email => `oauthor/v1/google/revoke/${email}`,
    regex: new RegExp(`^oauthor/v1/google/revoke/[^/]*`),
    methods: ['POST']
  },
  RENDER_MODULE_WITH_META: {
    getUrl: () => `cos-rendering/v1/internal/render/widget-with-meta`,
    regex: new RegExp(`^cos-rendering/v1/internal/render/widget-with-meta`),
    methods: ['POST']
  },
  ICON_SET: {
    getUrl: setName => `content-icons/v1/sets/${setName}`,
    regex: new RegExp(`^content-icons/v1/sets/[^/]*`),
    methods: ['GET']
  }
};