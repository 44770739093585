import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
export const addImageAltTextAudit = artifacts => {
  const images = artifacts[GathererKeys.IMAGES];
  const imagesWithoutAltText = images && images.filter(img => {
    const {
      alt,
      role
    } = img;
    return (alt === null || alt === undefined) && role !== 'presentation';
  });
  let status;

  if (imagesWithoutAltText === undefined || imagesWithoutAltText.length === 0) {
    status = CompletionStatuses.COMPLETE;
    return {
      status
    };
  } else {
    status = CompletionStatuses.INCOMPLETE;
    return {
      status,
      imagesWithoutAltText
    };
  }
};