import http from 'hub-http/clients/apiClient'; // https://tools.hubteam.com/api-catalog/services/ContentCategoriesService/v1/spec/internal#

const BASE_URL = 'content/categories/v1/categories'; // taken from https://git.hubteam.com/HubSpot/ContentCategories/blob/master/ContentCategoriesCore/src/main/java/com/hubspot/content/categories/core/AssetType.java

const MODULE = 'MODULE';
export const fetchCategoryTypes = () => {
  return http.get(`${BASE_URL}/meta`);
};
export const fetchCategorizedModules = () => {
  return http.get(`${BASE_URL}/${MODULE}`);
};