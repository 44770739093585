import { createSelector } from 'reselect';
import { requestStarted } from 'redux-request-state/util/requestStateCheckers'; // @ts-expect-error component not yet migrated

import { getContentSaveRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { getIsReadOnlyMode } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { SaveStates } from 'editor-components/EPConstants';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getSaveData = basicSelector(state => state.saveData);
export const getIsSaveInProgress = createSelector([getContentSaveRequest], contentSaveRequest => requestStarted(contentSaveRequest));
export const getIsSavePermanentlyDisabled = createSelector([getSaveData, getIsReadOnlyMode], (saveData, isReadOnlyMode) => saveData.permanentlyDisabled || isReadOnlyMode);
export const getWasLastSaveSuccessful = createSelector([getSaveData], saveData => Boolean(saveData.lastSaveSuccessful));
export const getHasUnsavedChanges = createSelector(getSaveData, saveData => saveData.isDirty);
export const getSaveRequestId = createSelector(getSaveData, saveData => saveData.saveRequestId);
export const getIsHardSavePending = createSelector(getSaveData, saveData => saveData.isHardSavePending);
export const getLastHardSaveTs = createSelector(getSaveData, saveData => saveData.lastHardSaveTs);
export const getPreventUnsavedChangesWarning = createSelector(getSaveData, saveData => saveData.preventUnsavedChangesWarning);
export const getSaveRequested = createSelector(getSaveData, saveData => saveData.saveRequested);
export const getLastSaveWasAutoSave = createSelector(getSaveData, saveData => saveData.lastSaveWasAutoSave);
export const getSaveState = createSelector([getIsSaveInProgress, getSaveRequested, getHasUnsavedChanges, getWasLastSaveSuccessful, getLastSaveWasAutoSave], (isSaveInProgress, saveRequested, hasUnsavedChanges, wasLastSaveSuccessful, lastSaveWasAutoSave) => {
  if (isSaveInProgress) {
    return SaveStates.SAVING_CHANGES;
  } else if (hasUnsavedChanges) {
    return SaveStates.UNSAVED_CHANGES;
  } else if (!saveRequested) {
    return SaveStates.NO_UNSAVED_CHANGES;
  } else if (wasLastSaveSuccessful) {
    if (lastSaveWasAutoSave) {
      return SaveStates.AUTO_SAVED_CHANGES;
    } else {
      return SaveStates.SAVED_CHANGES;
    }
  }

  return SaveStates.SAVE_FAILED;
});
export const getShouldSaveImmediately = createSelector(getSaveData, saveData => saveData.shouldSaveImmediately);
export const getErrorStatus = createSelector(getSaveData, saveData => saveData.errorStatus);
export const getErrorType = createSelector(getSaveData, saveData => saveData.errorType);
export const getErrorMessage = createSelector(getSaveData, saveData => saveData.errorMessage);